/** @module actions/warehouse */
import { getState } from 'src/utils/utilities'
import {
  selectAllWarehouses,
  selectAllWarehousesTypes,
  selectWarehouseLite,
} from 'src/selectors/warehouse.selector'
import WarehouseService from '../services/warehouse.service'
import {
  actionStart,
  clearActionResult,
  executeAction,
  executeError,
} from './global.actions'

export const actionTypes = {
  GET_ALL_WAREHOUSES: 'GET_ALL_WAREHOUSES',
  GET_ALL_WAREHOUSES2: 'GET_ALL_WAREHOUSES2',
  GET_ALL_USER_WAREHOUSES: 'GET_ALL_USER_WAREHOUSES',
  GET_EXTERNAL_ACTIVE_WAREHOUSES: 'GET_EXTERNAL_ACTIVE_WAREHOUSES',
  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  GET_ALL_WAREHOUSES_TYPES: 'GET_ALL_WAREHOUSES_TYPES',
  GET_SINGLE_WAREHOUSE: 'GET_SINGLE_WAREHOUSE',
  UPDATE_WAREHOUSE: 'UPDATE_WAREHOUSE',
  UPDATE_ATTENDANTS: 'UPDATE_ATTENDANTS',
  CREATE_WAREHOUSE: 'CREATE_WAREHOUSE',
  ADD_EXISTENCE_MANUAL_WAREHOUSE: 'ADD_EXISTENCE_MANUAL_WAREHOUSE',
  REMOVE_EXISTENCE_MANUAL_WAREHOUSE: 'REMOVE_EXISTENCE_MANUAL_WAREHOUSE',
  DELETE_WAREHOUSE: 'DELETE_WAREHOUSE',
  ACTIVE_WAREHOUSE: 'ACTIVE_WAREHOUSE',
  GET_WAREHOUSE_INVENTORY: 'GET_WAREHOUSE_INVENTORY',
  GET_EXISTENCE_BY_WAREHOUSE: 'GET_EXISTENCE_BY_WAREHOUSE',
  CREATE_TRANSFER: 'CREATE_TRANSFER',
  CREATE_TRANSFER_BY_GROUP: 'CREATE_TRANSFER_BY_GROUP',
  CREATE_TRANSFER_INVENTORY: 'CREATE_TRANSFER_INVENTORY',
  GET_TRANSFERS: 'GET_TRANSFERS',
  GET_TRANSFER_DETAIL: 'GET_TRANSFER_DETAIL',
  ACCEPT_TRANSFER: 'ACCEPT_TRANSFER',
  REJECT_TRANSFER: 'REJECT_TRANSFER',
  MASSIVE_TRANFER: 'MASSIVE_TRANFER',
  MASSIVE_VALIDATION: 'MASSIVE_VALIDATION',
  INFO_TRANSFER: 'INFO_TRANSFER',

  INVENTORY: 'INVENTORY',
  INVENTORY_REQUEST: 'INVENTORY_REQUEST',
  INVENTORY_WAREHOUSE: 'INVENTORY_WAREHOUSE',
  INVENTORY_SUCCESS: 'INVENTORY_SUCCESS',
  INVENTORY_ERROR: 'INVENTORY_ERROR',
  GET_ALL_CATEGORIZATIONS: 'GET_ALL_CATEGORIZATIONS',

  SET_INFO_TRANSFER_EXTERNAL: 'SET_INFO_TRANSFER_EXTERNAL',

  GET_INVENTORY_DETAIL: 'GET_INVENTORY_DETAIL',

  GET_INVENTORY_DETAILS: 'GET_INVENTORY_DETAILS',

  GET_TOTAL_PRODUCT_REPORT: 'GET_TOTAL_PRODUCT_REPORT',
  GET_EXCEL_TOTAL_PRODUCT_REPORT: 'GET_EXCEL_TOTAL_PRODUCT_REPORT',
  GET_TOTAL_PRODUCT_ADMIN_REPORT: 'GET_TOTAL_PRODUCT_ADMIN_REPORT',

  GET_ASSOCIATED_ORDERS: 'GET_ASSOCIATED_ORDERS',

  GET_ALL_WAREHOUSES_USER: 'GET_ALL_WAREHOUSES_USER',

  GET_INVENTORY_WITH_PRICE: 'GET_INVENTORY_WITH_PRICE',

  SET_ORIGIN: 'SET_ORIGIN',

  GET_ORDERS_PROCESSED: 'GET_ORDERS_PROCESSED',

  GET_ORDERS: 'GET_ORDERS',

  ON_VALIDATE_INVENTORY: 'ON_VALIDATE_INVENTORY',
  GET_LAST_INVENTORY_LOG: 'GET_LAST_INVENTORY_LOG',
  PURCHASE_REPORT: 'PURCHASE_REPORT',
  PURCHASE_REPORT_BY_WAREHOUSE: 'PURCHASE_REPORT_BY_WAREHOUSE',
  GET_EXCEL_BY_WAREHOUSE: 'GET_EXCEL_BY_WAREHOUSE',
  GET_DETAILED_EXCEL_BY_WAREHOUSE: 'GET_DETAILED_EXCEL_BY_WAREHOUSE',

  GET_HISTORICAL_INVENTORY: 'GET_HISTORICAL_INVENTORY',

  LOAD_CSV: 'LOAD_CSV',
  CHANGE_KOLO_STATE: 'CHANGE_KOLO_STATE',
  MASIVE_BATCH: 'MASIVE_BATCH',
  PRINT_QR: 'PRINT_QR',
  GET_WAREHOUSE_LITE: 'GET_WAREHOUSE_LITE',
  DOWNLOAD_GENERAL_KARDEX: 'DOWNLOAD_GENERAL_KARDEX',
  GET_ITEMS_TO_SELECT: 'GET_ITEMS_TO_SELECT',
  GET_ITEMS_TO_SELECT_DETAIL: 'GET_ITEMS_TO_SELECT_DETAIL',
  GET_ITEMS_TO_SELECT_BARCODE: 'GET_ITEMS_TO_SELECT_BARCODE',
  GET_TRANSFER_PDF: 'GET_TRANSFER_PDF',
  GET_LINE_ITEMS_TO_SELECT: 'GET_LINE_ITEMS_TO_SELECT',
  GET_INVENTORY_MISSING_ITEMS: 'GET_INVENTORY_MISSING_ITEMS',
  ON_UPDATE_STATUS_PENDING_MISSING_REQUEST: 'ON_UPDATE_STATUS_PENDING_MISSING_REQUEST',
  ON_CREATE_TRANSFER_MISSING_REQUEST: 'ON_CREATE_TRANSFER_MISSING_REQUEST',
  GET_INVENTORY_VARIATION: 'GET_INVENTORY_VARIATION',

  GET_GPS_WAREHOUSE: 'GET_GPS_WAREHOUSE',
  UPDATE_WIALON_WAREHOUSE: 'UPDATE_WIALON_WAREHOUSE',

  WAREHOUSE_INVENTORY_MIN_MAX: 'WAREHOUSE_INVENTORY_MIN_MAX',
  WAREHOUSE_INVENTORY_MIN_MAX_CREATE_UPDATE: 'WAREHOUSE_INVENTORY_MIN_MAX_CREATE_UPDATE',

  ON_CREATE_VISIT_DISPATCH: 'ON_CREATE_VISIT_DISPATCH',

  GET_WAREHOUSE_KARDEX_REPORT: 'GET_WAREHOUSE_KARDEX_REPORT',
  ON_VALIDATE_MANUFACTURE: 'ON_VALIDATE_MANUFACTURE',
  SYNC_KARDEX_SPREADSHEET: 'SYNC_KARDEX_SPREADSHEET',
}

const getInventoryRequest = () => ({ type: actionTypes.INVENTORY_REQUEST })
const getInventorySuccess = response => ({
  type: actionTypes.INVENTORY_SUCCESS,
  response,
})
const getInventoryError = error => ({
  type: actionTypes.INVENTORY_ERROR,
  error,
})
const setInfoTransferExternal = transfer => ({
  type: actionTypes.SET_INFO_TRANSFER_EXTERNAL,
  transfer,
})
const setOriginSuccess = origin => ({ type: actionTypes.SET_ORIGIN, origin })

export const setInformationTransfer = transfer => dispatch =>
  dispatch(setInfoTransferExternal(transfer))

export const setInventoryList = list => dispatch => dispatch(getInventorySuccess(list))

export const setOrigin = origin => dispatch => dispatch(setOriginSuccess(origin))
export const saveWarehouse = warehouse => dispatch =>
  dispatch({ type: `${actionTypes.GET_SINGLE_WAREHOUSE}_SUCCESS`, warehouse })

export const getInventory =
  (warehouse, all, reserve, log, providerId, start, end, search) => async dispatch => {
    dispatch(getInventoryRequest())
    try {
      const response = await WarehouseService.getWarehouseInventory(warehouse, {
        all,
        reserve,
        log,
        providerId,
        start,
        end,
        search,
      })
      dispatch(getInventorySuccess(response))
    } catch (e) {
      dispatch(getInventoryError(e))
    }
  }

export const getExistenceSingleWarehouseWithPriceProducts =
  (id, client, currentCompany, includeNoExistence, reserve, typeId) => async dispatch => {
    dispatch(
      getInventoryWithPrices(id, client.latitude, client.longitude, reserve, typeId),
    )
    /*
    dispatch(getInventoryRequest());
    return WarehouseService.existenceByWarehouse(id, includeNoExistence)
        .then((response) => {
            const promises = []
            response.map((p) => {
                promises.push(getInventoryPrices(p, client.latitude, client.longitude, currentCompany));
            });
            return Promise.all(promises);
        })
        .then((response) => dispatch(getInventorySuccess(response)))
        .catch((e) => dispatch(getInventoryError(e)));
    */
  }

export const getWarehouseInventoryByOrder = (warehouse, reserve) => dispatch => {
  const process = async () => ({
    warehouses: await WarehouseService.getWarehouseInventoryByOrder(warehouse, reserve),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS, process))
}

export const getWarehouses = (withReport, kolo, all) => dispatch => {
  const warehouses = getState(selectAllWarehouses)
  if (warehouses.length > 0) return

  const process = async () => ({
    warehouses: await WarehouseService.getWarehouses({ withReport, kolo, all }),
  })
  dispatch(executeAction(actionTypes.GET_ALL_WAREHOUSES, process))
}

export const getAllWarehouses = request => dispatch => {
  const process = async () => ({
    warehouses: await WarehouseService.getAllWarehouses(request),
  })
  dispatch(executeAction(actionTypes.GET_ALL_WAREHOUSES2, process))
}

export const getWarehousesByUser = userId => dispatch => {
  const process = async () => ({
    userWarehouses: await WarehouseService.getUserWarehouses(userId),
  })
  dispatch(executeAction(actionTypes.GET_ALL_USER_WAREHOUSES, process))
}

export const getWarehousesUser = () => dispatch => {
  const process = async () => ({
    warehouses: await WarehouseService.getWarehousesUser(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_WAREHOUSES_USER, process))
}

export const getExternalActiveWarehouses = companyId => dispatch => {
  const process = async () => ({
    warehouses: await WarehouseService.getExternalActiveWarehouses(companyId),
  })
  dispatch(executeAction(actionTypes.GET_EXTERNAL_ACTIVE_WAREHOUSES, process))
}

export const getProducts = () => dispatch => {
  const process = async () => ({
    products: await WarehouseService.getProducts(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS, process))
}

export const getWarehousesTypes = () => dispatch => {
  const types = getState(selectAllWarehousesTypes)
  if (types.length > 0) return

  const process = async () => ({
    warehousesTypes: await WarehouseService.getWarehousesTypes(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_WAREHOUSES_TYPES, process))
}

export const getSingleWarehouse = id => dispatch => {
  const process = async () => ({
    warehouse: await WarehouseService.getSingleWarehouse(id),
  })
  dispatch(executeAction(actionTypes.GET_SINGLE_WAREHOUSE, process))
}

export const updateSingleWarehouse = (id, request) => dispatch => {
  const process = async () => ({
    warehouse: await WarehouseService.updateWarehouse(id, request),
  })
  dispatch(executeAction(actionTypes.UPDATE_WAREHOUSE, process))
}

export const updateManyWarehouses = list => dispatch => {
  dispatch(actionStart(actionTypes.UPDATE_WAREHOUSE))
  const promises = []
  list.map(async l => {
    promises.push(WarehouseService.updateWarehouse(l.id, l))
  })
  return Promise.all(promises)
    .then(() => {
      dispatch(executeAction(actionTypes.UPDATE_WAREHOUSE))
    })
    .catch(response => dispatch(executeError(actionTypes.UPDATE_WAREHOUSE, response)))
}

export const setAttendantsWarehouses = request => dispatch => {
  const process = async () => ({
    warehouse: await WarehouseService.updateAttendants(request),
  })
  dispatch(executeAction(actionTypes.UPDATE_ATTENDANTS, process))
}

export const createSingleWarehouse = request => dispatch => {
  const process = async () => ({
    warehouse: await WarehouseService.createWarehouse(request),
  })
  dispatch(executeAction(actionTypes.CREATE_WAREHOUSE, process))
}

export const editMultipleExistenceWarehouse =
  (fromWarehouseId, productList, categorizationId) => dispatch => {
    const process = async () => {
      const result = await WarehouseService.editMultipleExistenceWarehouse(
        fromWarehouseId,
        productList,
        categorizationId,
      )
      dispatch(getInventory(fromWarehouseId, true))
      return { result }
    }
    dispatch(executeAction(actionTypes.MASIVE_BATCH, process))
  }

export const addExistenceManualWarehouse =
  (fromWarehouseId, productId, quantity, categorizationId) => dispatch => {
    const process = async () => {
      const result = await WarehouseService.addExistenceManualWarehouse(
        fromWarehouseId,
        productId,
        quantity,
        categorizationId,
      )
      dispatch(getInventory(fromWarehouseId, true))
      return { result }
    }
    dispatch(executeAction(actionTypes.ADD_EXISTENCE_MANUAL_WAREHOUSE, process))
  }

export const removeExistenceManualWarehouse =
  (fromWarehouseId, productId, quantity, categorizationId) => dispatch => {
    const process = async () => {
      const result = await WarehouseService.removeExistenceManualWarehouse(
        fromWarehouseId,
        productId,
        quantity,
        categorizationId,
      )
      dispatch(getInventory(fromWarehouseId, true))
      return { result }
    }
    dispatch(executeAction(actionTypes.REMOVE_EXISTENCE_MANUAL_WAREHOUSE, process))
  }

export const deleteSingleWarehouse = id => dispatch => {
  const process = async () => ({
    result: await WarehouseService.deleteWarehouse([id]),
  })
  dispatch(executeAction(actionTypes.DELETE_WAREHOUSE, process))
}

export const deleteByList = list => dispatch => {
  const process = async () => await WarehouseService.deleteWarehouse(list)
  dispatch(executeAction(actionTypes.DELETE_WAREHOUSE, process))
}

export const activeSingleWarehouse = id => async dispatch => {
  const process = async () => {
    const result = await WarehouseService.activeWarehouse(id)
    // dispatch(getWarehouses());
    return { result }
  }
  dispatch(executeAction(actionTypes.ACTIVE_WAREHOUSE, process))
}

export const getWarehouseInventory =
  (warehouse, includeNoExistence, reserve) => dispatch => {
    const process = async () => ({
      inventory: await WarehouseService.getWarehouseInventory(warehouse, {
        all: includeNoExistence,
        reserve,
      }),
    })
    dispatch(executeAction(actionTypes.GET_WAREHOUSE_INVENTORY, process))
  }

export const createTransfer =
  (
    fromWarehouseId,
    toWarehouseId,
    onConsignment,
    description,
    requested,
    detail,
    all,
    dispatchPending,
  ) =>
  async dispatch => {
    const process = async () => {
      await WarehouseService.createTransfer(
        fromWarehouseId,
        toWarehouseId,
        onConsignment,
        description,
        requested,
        detail,
        all,
        dispatchPending,
      )
      dispatch(clearActionResult(actionTypes.GET_WAREHOUSE_INVENTORY))
    }
    dispatch(executeAction(actionTypes.CREATE_TRANSFER, process))
  }

export const createTransferWithoutProduct = request => async dispatch => {
  const process = async () => {
    await WarehouseService.createTransferWithoutProduct(request)
  }
  dispatch(executeAction(actionTypes.CREATE_TRANSFER, process))
}

export const createTransferByOrder = (orderId, request, reserved) => async dispatch => {
  const process = async () => {
    await WarehouseService.createTransferByOrder(orderId, request, reserved)
  }
  dispatch(executeAction(actionTypes.CREATE_TRANSFER_BY_GROUP, process))
}

export const createTransferByGroup = (groupId, request) => async dispatch => {
  const process = async () => {
    await WarehouseService.createTransferByGroup(groupId, request)
  }
  dispatch(executeAction(actionTypes.CREATE_TRANSFER_BY_GROUP, process))
}

export const createTransferByList = (list, request) => async dispatch => {
  let promises = []
  list.map(async item => {
    if (item.type === 'group')
      promises.push(WarehouseService.createTransferByGroup(item.id, request))
    else promises.push(WarehouseService.createTransferByOrder(item.id, request))
  })
  return Promise.all(promises).then(() =>
    dispatch(executeAction(actionTypes.CREATE_TRANSFER)),
  )
}

export const createTransferInventory =
  (fromWarehouseId, toWarehouseId, onConsignment, description, details, categories) =>
  async dispatch => {
    const process = async () => {
      await WarehouseService.createTransferInventory(
        fromWarehouseId,
        toWarehouseId,
        onConsignment,
        description,
        categories,
      )
      dispatch(clearActionResult(actionTypes.GET_WAREHOUSE_INVENTORY))
    }
    dispatch(executeAction(actionTypes.CREATE_TRANSFER, process))
  }

export const getTransfersTransformations =
  (
    startDate,
    endDate,
    skip,
    size,
    external,
    recolect,
    stateId,
    search,
    warehouse,
    onlyTransfers,
    onlyTransformations,
    userId,
    documentType,
    withDetail,
    getTotal,
    documentId,
  ) =>
  dispatch => {
    const process = async () => ({
      transfers: await WarehouseService.getTransfersTransformations(
        startDate,
        endDate,
        skip,
        size,
        external,
        recolect,
        stateId,
        search,
        warehouse,
        onlyTransfers,
        onlyTransformations,
        userId,
        documentType,
        withDetail,
        getTotal,
        documentId,
      ),
    })
    dispatch(executeAction(actionTypes.GET_TRANSFERS, process))
  }

export const getTransferDetail = (id, withoutCompany) => dispatch => {
  const process = async () => ({
    transfer: await WarehouseService.getTransferDetail(id, withoutCompany),
  })
  dispatch(executeAction(actionTypes.GET_TRANSFER_DETAIL, process))
}

export const getCategorizations = () => dispatch => {
  const process = async () => ({
    categorizations: await WarehouseService.getCategorizations(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_CATEGORIZATIONS, process))
}

export const getInventoryDetail =
  (warehouseId, productId, startDate, endDate) => dispatch => {
    const process = async () => ({
      inventoryDetail: await WarehouseService.getInventoryDetail({
        warehouseId,
        productId,
        startDate,
        endDate,
      }),
    })
    dispatch(executeAction(actionTypes.GET_INVENTORY_DETAIL, process))
  }

export const approveTransferWithoutProduct = transferId => async dispatch => {
  const process = async () => {
    await WarehouseService.approveTransferWithoutProduct(transferId)
  }
  dispatch(executeAction(actionTypes.ACCEPT_TRANSFER, process))
}

export const rejectTransferWithoutProduct = transferId => async dispatch => {
  const process = async () => {
    await WarehouseService.rejectTransferWithoutProduct(transferId)
  }
  dispatch(executeAction(actionTypes.REJECT_TRANSFER, process))
}

export const acceptTransfer = (id, fromWarehouseId, toWarehouseId) => dispatch => {
  const process = async () => {
    await WarehouseService.approveTransfer(id, {
      fromWarehouseId,
      toWarehouseId,
    })
    dispatch(getTransferDetail(id))
  }
  dispatch(executeAction(actionTypes.ACCEPT_TRANSFER, process))
}

/** Action to confirm transfer in tuples
 * @param {number} id ID of transfer
 * @param {Object} request Request
 * @param {Array} request.detail List of items to confirm
 * @param {boolean} request.lastDetails Indicate if that request is the last
 */
export const acceptTransferMassive = (id, request) => dispatch => {
  const process = async () => ({
    response: await WarehouseService.approveTransferMassive(id, request),
  })
  dispatch(executeAction(actionTypes.MASSIVE_TRANFER, process))
}

/** Action to verify if the transfer have existence
 * @param {number} id ID of transfer
 * @param {Object} request Request
 * @param {Array} request.detail List of items to verify
 * @param {boolean} request.lastDetails Indicate if that request is the last
 */
export const validateInventoryTransfer = (id, request) => dispatch => {
  const process = async () => ({
    response: await WarehouseService.validateInventoryTransferMassive(id, request),
  })
  dispatch(executeAction(actionTypes.MASSIVE_VALIDATION, process))
}

/**
 * Action para cambiar infomracion de la carga masiva.
 * @param {Object} payload Payload
 * @returns None
 */
export const onSetInfoTransfer = payload => dispatch => {
  const process = async () => ({ payload })
  dispatch(executeAction(actionTypes.INFO_TRANSFER, process))
}

export const acceptMultipleTransfer = list => dispatch => {
  const promises = []
  list.map(async id => {
    promises.push(WarehouseService.approveTransfer(id))
  })
  Promise.all(promises)
    .then(() => {
      dispatch(executeAction(actionTypes.ACCEPT_TRANSFER))
    })
    .catch(response => dispatch(executeError(actionTypes.ACCEPT_TRANSFER, response)))
}

export const rejectTransfer = id => dispatch => {
  const process = async () => {
    await WarehouseService.rejectTransfer(id)
    dispatch(getTransferDetail(id))
  }
  dispatch(executeAction(actionTypes.REJECT_TRANSFER, process))
}

export const getOrdersProcessed = () => dispatch => {
  const process = async () => ({
    processed: await WarehouseService.getProcessedOrders(),
  })
  dispatch(executeAction(actionTypes.GET_ORDERS_PROCESSED, process))
}

export const getProductsReport =
  (sDate, eDate, distributor, deliverer, seller, request) => dispatch => {
    const process = async () => ({
      reportTotal: await WarehouseService.getTotalProductReport(
        sDate,
        eDate,
        distributor,
        deliverer,
        seller,
        request,
      ),
    })
    dispatch(executeAction(actionTypes.GET_TOTAL_PRODUCT_REPORT, process))
  }

export const getExcelProductsReport =
  (sDate, eDate, distributor, deliverer, seller, pos, request) => dispatch => {
    const process = async () => ({
      response: await WarehouseService.getExcelTotalProductReport(
        sDate,
        eDate,
        distributor,
        deliverer,
        seller,
        pos,
        request,
      ),
    })
    dispatch(executeAction(actionTypes.GET_EXCEL_TOTAL_PRODUCT_REPORT, process))
  }

export const getTotalProductAdminReport = (sDate, eDate) => dispatch => {
  const process = async () => ({
    reportTotalAdmin: await WarehouseService.getTotalProductAdminReport(sDate, eDate),
  })
  dispatch(executeAction(actionTypes.GET_TOTAL_PRODUCT_ADMIN_REPORT, process))
}

export const getAssociatedOrders = id => dispatch => {
  const process = async () => ({
    associatedOrders: await WarehouseService.getAssociatedOrders(id),
  })
  dispatch(executeAction(actionTypes.GET_ASSOCIATED_ORDERS, process))
}

export const getInventoryWithPrices =
  (
    warehouse,
    latitude,
    longitude,
    reserve,
    typeId,
    withZero,
    search,
    firstTen,
    list,
    barcode,
  ) =>
  dispatch => {
    const process = async () => ({
      inventory: await WarehouseService.getInventoryWithPrices({
        latitude,
        longitude,
        reserve,
        typeId,
        withZero,
        warehouse,
        search,
        firstTen,
        list,
        barcode,
      }),
    })
    dispatch(executeAction(actionTypes.GET_INVENTORY_WITH_PRICE, process))
  }

export const onValidateInventory = (warehouse, type, request) => dispatch => {
  const process = async () => ({
    lastLogInventory: await WarehouseService.onValidateInventory(
      warehouse,
      type,
      request,
    ),
  })
  dispatch(executeAction(actionTypes.ON_VALIDATE_INVENTORY, process))
}

export const getLastInventoryLog = (warehouse, productId, withReport) => dispatch => {
  const process = async () => ({
    lastLogInventory: await WarehouseService.getLastInventoryLog(warehouse, {
      productId,
      withReport,
    }),
  })
  dispatch(executeAction(actionTypes.GET_LAST_INVENTORY_LOG, process))
}

export const getPurchaseReport = warehouse => dispatch => {
  const process = async () => ({
    purchases: await WarehouseService.getPurchaseReport(warehouse),
  })
  if (warehouse)
    dispatch(executeAction(actionTypes.PURCHASE_REPORT_BY_WAREHOUSE, process))
  else dispatch(executeAction(actionTypes.PURCHASE_REPORT, process))
}

export const getExcelByWarehouse = (warehouse, params) => dispatch => {
  const process = async () => ({
    excel: await WarehouseService.getExcelByWarehouse(warehouse, params),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL_BY_WAREHOUSE, process))
}

export const getDetailedExcelByWarehouse = (warehouse, params) => dispatch => {
  const process = async () => ({
    excel: await WarehouseService.getDetailedExcelByWarehouse(warehouse, params),
  })
  dispatch(executeAction(actionTypes.GET_DETAILED_EXCEL_BY_WAREHOUSE, process))
}

const loadRequestCSV = (data, type) => {
  let response = {}

  let add = 'REQUEST'
  if (type === 2) {
    add = 'SUCCESS'
    response.csv = data
  } else if (type === 1) {
    add = 'ERROR'
    response.error = data
  }

  response.type = actionTypes.LOAD_CSV.concat('_' + add)
  return response
}

export const massiveLoadWarehouse = (data, currentIndex, onSuccess) => async dispatch => {
  const response = await WarehouseService.loadWarehouses(data, currentIndex)
  const process = async () => ({
    response,
  })
  dispatch(executeAction(actionTypes.LOAD_CSV, process))
  onSuccess(response, false)
}

export const getInventoryHistory = (warehouse, params) => dispatch => {
  const process = async () => ({
    historical: await WarehouseService.getInventoryHistory(warehouse, params),
  })
  dispatch(executeAction(actionTypes.GET_HISTORICAL_INVENTORY, process))
}

export const getInventoryReport =
  (warehouseId, request, start, end, type) => dispatch => {
    const process = async () => ({
      inventoryDetails: await WarehouseService.getInventoryReport(
        warehouseId,
        request,
        start,
        end,
        type,
      ),
    })
    dispatch(executeAction(actionTypes.GET_INVENTORY_DETAILS, process))
  }

export const changeKoloState = warehouse => dispatch => {
  const process = async () => await WarehouseService.changeKoloState(warehouse)
  dispatch(executeAction(actionTypes.CHANGE_KOLO_STATE, process))
}

export const changeKoloDeliveryState = warehouse => dispatch => {
  const process = async () => await WarehouseService.changeKoloDeliveryState(warehouse)
  dispatch(executeAction(actionTypes.CHANGE_KOLO_STATE, process))
}

export const printQR = warehouseId => dispatch => {
  const process = async () => await WarehouseService.printQR(warehouseId)
  dispatch(executeAction(actionTypes.PRINT_QR, process))
}

/**
 * Get inventory by warehouse
 * @param {Number} warehouseId Warehouse id
 * @param {Object} params Query params to filter inventory
 * @param {boolean} params.includeReserved Include reserved inventory
 * @param {boolean} params.includePending Include pending inventory
 * @param {number} params.start Pagination start
 * @param {number} params.size Pagination size
 * @param {string | undefined} params.search Search string
 * @param {number} params.typeId Type id
 * @param {string} params.categorization Filter by categorizations
 */
export const getInventoryExistence = (warehouseId, params) => dispatch => {
  const process = async () => ({
    response: await WarehouseService.getWarehouseInventory2(warehouseId, params),
  })
  dispatch(executeAction(actionTypes.INVENTORY_WAREHOUSE, process))
}

export const getWarehouseLite = (params, force) => dispatch => {
  if (!force) {
    const warehouses = getState(selectWarehouseLite)
    if (warehouses.length > 0) return
  }

  const process = async () => ({
    response: await WarehouseService.getWarehouseLite(params),
  })
  dispatch(executeAction(actionTypes.GET_WAREHOUSE_LITE, process))
}

export const exportWarehouseKardex =
  (warehouseId, start, end, name, productId, movements) => dispatch => {
    const process = async () =>
      await WarehouseService.exportWarehouseKardex(
        { warehouseId, start, end, productId, movements },
        name,
      )
    dispatch(executeAction(actionTypes.DOWNLOAD_GENERAL_KARDEX, process))
  }

export const getItemsToSelect = params => dispatch => {
  const process = async () => ({
    items: await WarehouseService.getItemsToSelect(params),
  })
  dispatch(executeAction(actionTypes.GET_ITEMS_TO_SELECT, process))
}

export const getItemsToSelectDetail = params => dispatch => {
  const process = async () => ({
    items: await WarehouseService.getItemsToSelect(params),
  })
  dispatch(
    executeAction(
      params.barcode
        ? actionTypes.GET_ITEMS_TO_SELECT_BARCODE
        : actionTypes.GET_ITEMS_TO_SELECT_DETAIL,
      process,
    ),
  )
}

export const getLineItemsToSelect = (request, params) => dispatch => {
  if (params.productId === 1) return

  const process = async () => ({
    data: await WarehouseService.getLineItemsToSelect(request, params),
  })
  dispatch(executeAction(actionTypes.GET_LINE_ITEMS_TO_SELECT, process))
}

export const getTransferPDF = (id, name) => dispatch => {
  const process = async () => await WarehouseService.getTransferPDF(id, name)
  dispatch(executeAction(actionTypes.GET_TRANSFER_PDF, process))
}

export const getPendingMissingRequest = (warehouseId, params) => dispatch => {
  const process = async () => ({
    response: await WarehouseService.getPendingMissingRequest(warehouseId, params),
  })
  dispatch(executeAction(actionTypes.GET_INVENTORY_MISSING_ITEMS, process))
}

export const onUpdateStatusPendingMissingRequest = (request, params) => dispatch => {
  const process = async () =>
    await WarehouseService.onUpdateStatusPendingMissingRequest(request, params)
  dispatch(executeAction(actionTypes.ON_UPDATE_STATUS_PENDING_MISSING_REQUEST, process))
}

export const onCreateTransferMissingRequest = (request, params) => dispatch => {
  const process = async () =>
    await WarehouseService.onCreateTransferMissingRequest(request, params)
  dispatch(executeAction(actionTypes.ON_CREATE_TRANSFER_MISSING_REQUEST, process))
}

export /**
 * Action para obtener los items por su variacion
 * @param {Number} warehouseId Id de bodega
 * @param {Number} productId Id de producto
 * @param {Object} params Parametros
 * @returns None
 */
const getWarehouseInventoryVariation = (warehouseId, productId, params) => dispatch => {
  const process = async () => ({
    response:
      warehouseId !== null
        ? await WarehouseService.getWarehouseInventoryVariation(
            warehouseId,
            productId,
            params,
          )
        : {},
  })
  dispatch(executeAction(actionTypes.GET_INVENTORY_VARIATION, process))
}

/**
 * Action para obtener los items filtrados por su variacion
 * @param {Number} warehouseId  Id de bodega
 * @param {String} variations   Listado de id variaciones
 * @returns None
 */
export const getWarehouseInventoryByVariations =
  (warehouseId, variations) => dispatch => {
    const process = async () => ({
      response: await WarehouseService.getWarehouseInventoryByVariations(warehouseId, {
        variations,
      }),
    })
    dispatch(executeAction(actionTypes.GET_INVENTORY_VARIATION, process))
  }

export /**
 * Action para peticion de obtener posicion de la bodega.
 * @param {Number} warehouseId  Id de la bodega
 * @returns None
 */
const getGPSWarehouse = warehouseId => dispatch => {
  const process = async () => ({
    response: await WarehouseService.getGPSWarehouse(warehouseId),
  })

  dispatch(executeAction(actionTypes.GET_GPS_WAREHOUSE, process))
}

export /**
 * Action para actualizar el Wialon Id de una bodega.
 * @param {Number} warehouseId Id de la bodega
 * @param {Number} wialonId Id Wialon
 * @returns None
 */
const updateWialonWarehouse = (warehouseId, wialonId) => dispatch => {
  const process = async () => ({
    response: await WarehouseService.setWailonIdWarehouse(warehouseId, {
      wialonId: wialonId,
    }),
  })
  dispatch(executeAction(actionTypes.UPDATE_WIALON_WAREHOUSE, process))
}

/**
 * Action para obtener el listado de ítems configurados en la bodega para mínimos y máximos
 * @param {Number} warehouseId id de la bodega
 * @returns None
 */
export const getMaxMinConfigurationToWarehouse = warehouseId => dispatch => {
  const process = async () => ({
    response: await WarehouseService.getMaxMinConfigurationToWarehouse(warehouseId),
  })
  dispatch(executeAction(actionTypes.WAREHOUSE_INVENTORY_MIN_MAX, process))
}

/**
 * Action para crear una nueva configuración en la bodega para mínimos y máximos
 * @param {Number} warehouseId id de la bodega
 * @param {Array} items Listado de ítems a configurar
 * @returns None
 */
export const createMaxMinItemToWarehouse = (warehouseId, items) => dispatch => {
  const process = async () => ({
    response: await WarehouseService.createMaxMinItemToWarehouse(warehouseId, items),
  })
  dispatch(executeAction(actionTypes.WAREHOUSE_INVENTORY_MIN_MAX_CREATE_UPDATE, process))
}

/**
 * Action para actualizar una configuración existente en la bodega para mínimos y máximos
 * @param {Number} warehouseId id de la bodega
 * @param {Array} items Listado de ítems a configurar
 * @returns None
 */
export const updateMaxMinItemToWarehouse = (warehouseId, items) => dispatch => {
  const process = async () => ({
    response: await WarehouseService.updateMaxMinItemToWarehouse(warehouseId, items),
  })
  dispatch(executeAction(actionTypes.WAREHOUSE_INVENTORY_MIN_MAX_CREATE_UPDATE, process))
}

export const onCreateDispatchVisits = (request, params) => dispatch => {
  const process = async () =>
    await WarehouseService.onCreateDispatchVisits(request, params)
  dispatch(executeAction(actionTypes.ON_CREATE_VISIT_DISPATCH, process))
}

export const getWarehouseKardexReport =
  (warehouseId, start, end, movements) => dispatch => {
    const process = async () => ({
      response: await WarehouseService.getWarehouseKardexReport({
        warehouseId,
        start,
        end,
        movements,
      }),
    })
    dispatch(executeAction(actionTypes.GET_WAREHOUSE_KARDEX_REPORT, process))
  }

export const onValidateTransfer = manufactureId => dispatch => {
  const process = async () => ({
    response: await WarehouseService.onValidateTransfer(manufactureId),
  })
  dispatch(executeAction(actionTypes.ON_VALIDATE_MANUFACTURE, process))
}

export const syncKardexSpreadsheet = warehouseId => dispatch => {
  const process = async () => ({
    response: await WarehouseService.syncKardexSpreadsheet(warehouseId),
  })
  dispatch(executeAction(actionTypes.SYNC_KARDEX_SPREADSHEET, process))
}

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import Alert from 'sweetalert-react'

import {
  TableV2,
  Button,
  CustomReference,
  FormText,
  Icon,
  Dropdown,
  FilterByCategory,
  Folder,
  SelectedDates,
  Select,
  Title,
  Card,
} from '../index'

import {
  faBook,
  faTags,
  faTimes,
  faPlusCircle,
  faFileExcel,
  faTag,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes as typeC,
  getClientLog,
  createLog,
  updateItemLog,
  getLogReport,
  getClientsSimple,
  getAllClients,
  downloadLogExcel,
} from 'src/actions/clients.actions'
import {
  selectClientLog,
  selectTotalLogClients,
  simpleClients,
} from 'src/selectors/clients.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerError,
  handlerSuccess,
  singleErrorSelector,
  hasErrorsSelector,
} from 'src/selectors/error.selector'

import { isAllowed, selectUsersToSelect } from 'src/selectors/modules.selector'

import { formatDateFromMillis } from 'src/utils/formatters'
import { selectCurrentCompany, selectCurrentModule } from 'src/selectors/user.selector'

import {
  selectAllCategorizations,
  selectCategorizationAssignedForSelect,
} from 'src/selectors/categorizations.selector'
import {
  actionTypes as actionC,
  assignCategorizationToMultipleEntities,
  getAllCategorizations,
  getCategorizationsByEntity,
  onSetLocalCategories,
} from 'src/actions/categorization.actions'

import { useLocation } from 'react-router-dom'
import { selectCurrentUser } from 'src/selectors/user.selector'
import Switch from '../inputs/Switch/Switch'
import IconButton from '../buttons/IconButton'
import ButtonIcon from '../buttons/IconButton'
import { getUsersChildrenByModule } from 'src/actions/modules.actions'

import { actionTypes as typeP, getLog } from 'src/actions/products.actions'
import { selectProductLog } from 'src/selectors/products.selector'

import { categoryType } from '../folders/Folder'
import { onSetModalOrder, onSetModalPurchase } from 'src/actions/utilities.actions'

const ModalLog = ({
  id,
  logType = undefined,
  onHide,
  title,
  modal,
  newlog = undefined,
  search = undefined,
  product,
  access = undefined,
  balancePermission = undefined,
}) => {
  const dispatch = useDispatch()

  const company = useSelector(selectCurrentCompany)
  const module = useSelector(selectCurrentModule)

  const user = useSelector(selectCurrentUser)
  const allUser = useSelector(selectUsersToSelect)

  const clients = useSelector(simpleClients)

  const log = useSelector(selectClientLog)
  const productLog = useSelector(selectProductLog)
  const loading = useSelector(state =>
    loadingSelector([typeC.CLIENT_LOG, typeC.REPORT_LOG, typeP.GET_LOG])(state),
  )
  const download = useSelector(state => loadingSelector([typeC.REPORT_LOG_EXCEL])(state))
  const total = useSelector(selectTotalLogClients)

  const loadingR = useSelector(state => loadingSelector([typeC.CREATE_LOG])(state))
  const hasError = useSelector(state => hasErrorsSelector([typeC.CREATE_LOG])(state))
  const error = useSelector(state => singleErrorSelector([typeC.CREATE_LOG])(state))

  const loadingL = useSelector(state => loadingSelector([typeC.UPDATE_L0G])(state))
  const hasErrorL = useSelector(state => hasErrorsSelector([typeC.UPDATE_L0G])(state))
  const errorL = useSelector(state => singleErrorSelector([typeC.UPDATE_L0G])(state))

  const assigned = useSelector(selectCategorizationAssignedForSelect)
  const loadingAssigned = useSelector(state =>
    loadingSelector([actionC.GET_ASSIGNED, actionC.SET_LOCAL_CATEGORIES])(state),
  )
  const categorization = useSelector(selectAllCategorizations)
  const addIsLoading = useSelector(state =>
    loadingSelector([actionC.ADD_ENTITY_CATEGORIZATION])(state),
  )
  const addHasError = useSelector(state =>
    hasErrorsSelector([actionC.ADD_ENTITY_CATEGORIZATION])(state),
  )
  const addError = useSelector(state =>
    singleErrorSelector([actionC.ADD_ENTITY_CATEGORIZATION])(state),
  )

  const [report] = useState(useLocation().pathname.includes('/logs'))

  //Permissions
  const create = useSelector(state => isAllowed(state, [1060, 2159]))
  const update = useSelector(state => isAllowed(state, [1061, 2160]))
  const assign = useSelector(state => isAllowed(state, [1062, 2162]))
  const admin = useSelector(state => isAllowed(state, [1001, 2001, 11651]))

  const [show, setShow] = useState(false)
  const [action, setAction] = useState({
    see: false,
    id: null,
    type: null,
  })
  const [actions, setActions] = useState({ update: false, add: false })
  const [alert, setAlert] = useState({ title: '' })
  const [commentary, setCommentary] = useState({ show: false })
  const [register, setRegister] = useState(false)
  const [selected, setSelected] = useState(null)
  const [history, setStatusHistory] = useState({ show: false })
  const [showFolder, setShowFolder] = useState(false)
  const [selectedLogs, setSelectedLogs] = useState([])
  const [filters, setFilters] = useState({
    skip: 0,
    size: 10,
    search: null,
    start: null,
    end: null,
  })
  const [selectedClients, setSelectedClients] = useState([])
  const [accessClient, setAccessClient] = useState({
    value: 0,
    label: 'Seleccione un cliente',
  })
  const [accessLog, setAccessLog] = useState({
    id: null,
    commentary: '',
    categorization: [],
  })
  const [selectedUser, setSelectedUser] = useState({
    value: null,
    label: 'Seleccione un usuario',
  })

  const [checked, setChecked] = useState(false)
  const [selectType, setSelectType] = useState({ value: null, label: 'Todos los tipos' })

  const types = [
    { value: 1, label: 'Ventas' },
    { value: 2, label: 'Pagos' },
    { value: 7, label: 'Visitas' },
    { value: 8, label: 'Activación' },
    { value: 9, label: 'Inactivación' },
    { value: 10, label: 'Ediciones' },
    { value: 11, label: 'Creaciones' },
  ]

  useEffect(() => {
    if (!id || loading) return

    if (report) {
      dispatch(getAllCategorizations(5))
      dispatch(getClientsSimple(admin ? null : user.id))
      dispatch(getUsersChildrenByModule(module, true))
    }

    if (!show) {
      if (newlog) {
        setCommentary({ show: true })
      } else {
        setShow(true)
        setup()
      }
    }
  }, [id])

  useEffect(() => {
    if (!access) return
    setCommentary({ show: true })
    dispatch(getAllCategorizations(5))
    dispatch(getClientsSimple(admin ? null : user.id))
  }, [access])

  useEffect(() => {
    if (addIsLoading) setActions({ ...actions, add: true })
    else if (actions.add) {
      setActions({ ...actions, add: false })
      if (addHasError)
        setAlert({
          ...handlerError(addError.message || 'Error al asignar la categoría'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (selected) dispatch(getCategorizationsByEntity('clients', selected.id, true))
        if (!report) dispatch(getClientLog(id, logType, selectType.value))
      }
    }
  }, [addIsLoading])

  useEffect(() => {
    if (loadingR) setRegister(true)
    else if (register) {
      setRegister(false)
      if (hasError)
        setAlert({
          ...handlerError(error.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else if (!access) {
        dispatch(
          getAllClients(
            null,
            null,
            null,
            null,
            1,
            0,
            '10',
            search,
            null,
            null,
            null,
            null,
          ),
        )
      }
      setChecked(false)
      setAlert({
        ...handlerSuccess('Registro exitoso'),
        onConfirm: () => {
          setAlert({ ...alert, show: false })
          if (!access) {
            onHide()
            setCommentary({ show: false })
            setShow(true)
            dispatch(getClientLog(id, logType, selectType.value))
          } else {
            onHide()
            setAccessLog({ id: null, commentary: '', categorization: [] })
          }
        },
      })
    }
  }, [loadingR])

  useEffect(() => {
    if (loadingL) setActions({ ...action, update: true })
    else if (actions.update) {
      setSelected(null)
      setActions({ ...action, update: false })
      if (hasErrorL) {
        setAlert({
          ...handlerError(errorL.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        dispatch(getClientLog(id, logType, selectType.value))
      }
    }
  }, [loadingL])

  useEffect(() => {
    if (!loadingAssigned && !showFolder && !loading && show) setup()
  }, [filters, selectedClients, selectedUser, loadingAssigned, selectType])

  const setup = () => {
    if (report) {
      let request = {
        categories: assigned.map(p => p.value),
        clients: selectedClients.map(c => c.id),
        user: selectedUser.id,
      }
      if (report)
        dispatch(
          getLogReport(
            request,
            filters.start,
            filters.end,
            filters.skip,
            filters.size,
            filters.search,
            selectType.value,
          ),
        )
    } else if (product) {
      dispatch(getLog(id, null, null))
    } else {
      dispatch(getClientLog(id, logType, selectType.value, company === 0))
    }
  }

  const seeReference = item => {
    if (product) {
      const referenceId = item.reference
      switch (item.typeId) {
        case 2: // SALE
          dispatch(onSetModalOrder(referenceId))
          break
        case 3: // PURCHASE
          dispatch(onSetModalPurchase(referenceId))
          break
        default:
          onGenericReference(item)
          break
      }
    } else onGenericReference(item)
  }

  const onGenericReference = item => {
    setAction({ see: true, id: item.reference, type: product ? item.typeId : 1 })
    setShow(false)
  }

  const updateItem = (item, status) => {
    setSelected(item.id)
    dispatch(updateItemLog(item.id, status))
  }

  const getStatusName = item => {
    switch (item.status) {
      case 1:
        return 'Pendiente'
      case 2:
        return 'Exitoso'
      case 3:
        return 'No exitoso'
      default:
        return ''
    }
  }

  const isSelected = item => {
    const select = selectedLogs.find(s => item.id === s.id)
    return select !== undefined
  }

  const addOrRemoveSelected = item => {
    const cs = Object.assign([], selectedLogs)
    if (isSelected(item)) {
      //remove
      setSelectedLogs(cs.filter(s => s.id !== item.id))
    } else {
      //add
      cs.push(item)
      setSelectedLogs(cs)
    }
  }

  const assignCategorizations = id => {
    let array = selectedLogs.map(c => c.id)
    if (array.length === 0) array.push(selected.id)
    dispatch(
      assignCategorizationToMultipleEntities(
        'clients',
        id,
        array,
        selected ? !!selected.id : false,
        true,
      ),
    )
  }

  const filter = item => {
    if (!assigned || assigned.length === 0) return true
    const array = item.categorization ? item.categorization.map(d => d.id) : []
    return assigned.filter(p => array.findIndex(a => a === p.value) !== -1).length > 0
  }

  const removeSelected = item => {
    if (!access) return item

    let object = item
    let array = [...item.children]
    array = array.filter(s => {
      return accessLog.categorization.filter(f => f.id === s.id).length === 0
    })
    object.children = array
    return object
  }

  const customFilter = () => {
    return (
      <div>
        {report && (
          <>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) => setFilters({ ...filters, start, end })}
            />
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Select
                  label={'Filtro por usuario creador'}
                  info={
                    'Filtra los registro de la bitácora de cliente por el usuario quien creo el registro'
                  }
                  options={[{ value: null, label: 'Seleccione un usuario' }, ...allUser]}
                  value={selectedUser}
                  onChange={value => {
                    setSelectedUser(value)
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Select
                  label={'Filtro por cliente'}
                  options={clients.filter(s => {
                    return selectedClients.filter(f => f.id === s.id).length === 0
                  })}
                  value={{ value: 0, label: 'Seleccione un cliente' }}
                  onChange={value => {
                    selectedClients.push(value)
                    setSelectedClients([...selectedClients])
                  }}
                />
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Row className={'pl-1'}>
                  {selectedClients.map(c => (
                    <div key={c.id} className={'user-tag ml-2 mt-2'}>
                      <label className={'label-user-tag'}>{c.label}</label>
                      <ButtonIcon
                        className={'delete-user-tag'}
                        icon={faTimes}
                        tooltip={'Remover'}
                        color={'white'}
                        onClick={() =>
                          setSelectedClients([
                            ...selectedClients.filter(d => d.value !== c.value),
                          ])
                        }
                      />
                    </div>
                  ))}
                </Row>
              </Col>
            </Row>
          </>
        )}
        <Select
          label={'Filtrar por tipo'}
          options={[{ value: null, label: 'Todos los tipos' }, ...types]}
          value={selectType}
          onChange={value => {
            setSelectType(value)
          }}
        />
        {company !== 0 && !product && (
          <div className={'mt-3'}>
            <b className={'mb-3'}>Filtro por categoría</b>
            <FilterByCategory />
          </div>
        )}
      </div>
    )
  }

  const getButton = () => {
    return (
      report && (
        <Row>
          <Icon
            spin={download}
            style={{ marginRight: '15px' }}
            icon={faFileExcel}
            color={'green'}
            tooltip={'Descargar Excel'}
            onClick={() => {
              let request = {
                categories: assigned.map(p => p.value),
                clients: selectedClients.map(c => c.id),
                user: selectedUser.id,
              }
              dispatch(
                downloadLogExcel(request, {
                  start: filters.start,
                  end: filters.end,
                  search: filters.search,
                  refType: selectType.value,
                }),
              )
            }}
          />
        </Row>
      )
    )
  }

  const getType = type => {
    return type === 1
      ? 'Receta'
      : type === 2
      ? 'Venta'
      : type === 3
      ? 'Compra'
      : type === 4
      ? 'Gasto'
      : type === 5
      ? 'Producción'
      : type === 6
      ? 'Merma'
      : type === 7
      ? 'Transferencia'
      : type === 8
      ? 'Transformación'
      : type === 9
      ? 'Comanda de la mesa'
      : 'Edición del producto'
  }

  const render = () => {
    return (
      <>
        <TableV2
          loading={loading}
          items={product ? productLog : report ? log : log.filter(filter)}
          customFilter={customFilter()}
          total={report ? total : null}
          getPagination={(skip, size, search) =>
            report ? setFilters({ ...filters, skip, size, search }) : {}
          }
          handleChange={search =>
            report
              ? setTimeout(() => {
                  setFilters({ ...filters, search })
                }, 1000)
              : {}
          }
          renderRow={(item, index) => (
            <div key={index} className={`b-item ${isSelected(item) && 'active'}`}>
              <div
                className={`space-between`}
                onClick={() => {
                  if (logType === 1 && assign) addOrRemoveSelected(item)
                }}>
                {' '}
                <div
                  className="justify-content-start align-items-start bu-en left"
                  style={{ color: 'gray' }}>
                  {product ? (
                    <div className={`b-user-name ${isSelected(item) && 'active'}`}>
                      {item.user ? item.user.name : ''} -{' '}
                      {formatDateFromMillis(item.createdAt)}
                    </div>
                  ) : (
                    <div className={`b-user-name ${isSelected(item) && 'active'}`}>
                      {item.createdData ? item.createdData.name : ''} -{' '}
                      {formatDateFromMillis(item.createdAt)}
                    </div>
                  )}
                  {report && (
                    <div className={`b-user-email ${isSelected(item) && 'active'}`}>
                      Cliente: {item.clientName}
                    </div>
                  )}
                  {product ? (
                    <div className={`b-user-email ${isSelected(item) && 'active'}`}>
                      Registro: {getType(item.typeId)}
                    </div>
                  ) : (
                    <div className={`b-user-email ${isSelected(item) && 'active'}`}>
                      Registro: {item.type === 1 ? 'Manual' : 'Automático'}
                    </div>
                  )}

                  {item.type === 1 && (
                    <div className={'b-user-balance-m'}>{getStatusName(item)}</div>
                  )}
                </div>
                <div className="d-flex row align-items-center mr-2 bu-b right">
                  {item.type === 1 && (
                    <div className={'b-user-balance mr-2'}>{getStatusName(item)}</div>
                  )}
                  {product
                    ? item.reference &&
                      company !== 0 && (
                        <Icon
                          color={'gray'}
                          icon={faBook}
                          className={'mr-2'}
                          tooltip={'Ver referencia'}
                          size={'2x'}
                          onClick={() => seeReference(item)}
                        />
                      )
                    : item.type === 2 &&
                      item.reference &&
                      company !== 0 && (
                        <Icon
                          color={'gray'}
                          icon={faBook}
                          className={'mr-2'}
                          tooltip={'Ver referencia'}
                          size={'2x'}
                          onClick={() => seeReference(item)}
                        />
                      )}
                  {item.type === 1 && (item.reference || update) && company !== 0 && (
                    <Dropdown
                      disabled={loadingL && selected !== item.id}
                      loading={selected === item.id && loadingL}
                      items={[
                        {
                          show: item.reference,
                          title: 'Ver referencia',
                          action: () => seeReference(item),
                        },
                        {
                          show: item.reference && !product,
                          title: 'Asignar categorías',
                          action: () => {
                            dispatch(getCategorizationsByEntity('clients', item.id, true))
                            setSelected(item)
                            setShowFolder(true)
                          },
                        },
                        {
                          show: update && item.status !== 1,
                          title: 'Marcar como pendiente',
                          action: () => updateItem(item, 1),
                        },
                        {
                          show: update && item.status !== 2,
                          title: 'Marcar como exitoso',
                          action: () => updateItem(item, 2),
                        },
                        {
                          show: update && item.status !== 3,
                          title: 'Marcar como no exitoso',
                          action: () => updateItem(item, 3),
                        },
                        {
                          title: 'Ver historial de estados',
                          action: () => {
                            setStatusHistory({ ...item, show: true })
                          },
                        },
                      ]}
                    />
                  )}
                  {(item.type === 2 || (!item.reference && !update)) && (
                    <Icon
                      color={'gray'}
                      icon={faTags}
                      tooltip={'Asignar categorías'}
                      size={'2x'}
                      onClick={() => {
                        dispatch(getCategorizationsByEntity('clients', item.id, true))
                        setSelected(item)
                        setShowFolder(true)
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={'left'}>{item.description}</div>
              <div className={'ml-1 w-100 d-flex tag-log'} key={index}>
                {item.categorization &&
                  item.categorization.map(d => (
                    <div
                      key={d.id}
                      className={`c-item custom-tag child tag-container mr-1`}>
                      <Icon icon={faTag} />
                      <div className={'ml-1'}>{d.name}</div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        />
      </>
    )
  }

  return (
    <>
      {modal ? (
        <Modal
          show={show && !showFolder}
          centered
          size={'lg'}
          onHide={() => {
            setShow(false)
            onHide()
          }}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{render()}</Modal.Body>
          <Modal.Footer>
            <Row className={'container-buttons'}>
              {selectedLogs && selectedLogs.length > 0 && assign && (
                <Button onClick={() => setShowFolder(true)}>Asignar Categoría</Button>
              )}
              {create && !product && company !== 0 && (
                <Button
                  color={'secondary'}
                  onClick={() => {
                    setCommentary({ show: true })
                    setShow(false)
                  }}>
                  Crear registro
                </Button>
              )}
            </Row>
          </Modal.Footer>
        </Modal>
      ) : (
        <div>
          <Row className={'justify-content-center'}>
            <Col sm={12} md={8}>
              <Title title={'Bitacora de Clientes'} />
              <Card title={'logs'} white button={getButton()}>
                {render()}
              </Card>
            </Col>
          </Row>
        </div>
      )}

      <Modal
        show={commentary.show}
        centered
        size={'md'}
        onHide={() => {
          if (!loading) {
            setCommentary({ show: false })
            if (newlog) onHide()
            else setShow(true)
          }
        }}>
        {' '}
        <Modal.Header closeButton={!loading}>
          <Modal.Title>Registrar un comentario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {access && (
              <Select
                label={'Cliente que guardara el registro'}
                options={clients}
                value={accessClient}
                onChange={value => {
                  setAccessClient(value)
                  setAccessLog({ ...accessLog, id: value.id })
                }}
              />
            )}

            <FormText
              disabled={loadingR}
              placeholder={'Comentario'}
              value={accessLog.commentary}
              as={'textarea'}
              rows={3}
              max={2000}
              onChange={({ target }) =>
                setAccessLog({ ...accessLog, commentary: target.value })
              }
            />
            <Button
              className={'big-button'}
              style={{ width: 210, height: 35, lineHeight: 1 }}
              onClick={() => setShowFolder(true)}>
              <IconButton
                icon={faPlusCircle}
                color={'white'}
                size={'1x'}
                iconStyle={{ marginRight: 5 }}
              />
              Añadir Categorías
            </Button>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexFlow: 'wrap',
              }}>
              {accessLog.categorization.map(c => (
                <div key={c.id} className="chip mb-3">
                  <div className="chip-head">{c.name.charAt(0)}</div>
                  <div className="chip-content">{c.name}</div>
                  <div
                    className="chip-close"
                    onClick={() => {
                      let ids = [...accessLog.categorization]
                      ids = ids.filter(p => c.id !== p.id)
                      setAccessLog({ ...accessLog, categorization: ids })
                      dispatch(getAllCategorizations(5))
                    }}>
                    <svg
                      className="chip-svg"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true">
                      <path
                        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
                                  15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Switch
              label={'¿Desea que el registro sea visible en el listado de clientes?'}
              checked={checked}
              id={'checked'}
              onChange={({ target }) => {
                const { checked } = target
                setChecked(checked)
              }}
            />
            <Button
              disabled={
                access ? !(accessLog.id && accessLog.commentary) : !accessLog.commentary
              }
              loading={loadingR}
              color={'primary'}
              onClick={() => {
                let categorizations = accessLog.categorization.map(s => s.id)
                dispatch(
                  createLog(
                    access ? accessLog.id : id,
                    accessLog.commentary,
                    access ? 1 : logType,
                    checked,
                    categorizations,
                  ),
                )
              }}>
              Registrar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <CustomReference
        show={action.see}
        documentModule={product ? 8 : 7}
        documentType={action.type}
        documentId={action.id}
        onHide={() => {
          setAction({ ...action, type: null, id: null, see: false })
          setShow(true)
        }}
        balancePermission={balancePermission}
      />

      <Modal
        show={history.show}
        centered
        size={'md'}
        onHide={() => setStatusHistory({ show: false })}>
        {' '}
        <Modal.Header closeButton>
          <Modal.Title>Historial de cambios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableV2
            hideFilter
            items={history.history}
            renderRow={(item, index) => (
              <div className={'b-user-item'} key={index}>
                <div className={'justify-content-start align-items-start bu-en left'}>
                  <div className={'b-user-name'}>{item.statusName}</div>
                  <div className={'b-user-email'}>{formatDateFromMillis(item.date)}</div>
                  <div className={'b-user-balance-m'}>
                    {item.user ? item.user.name : ''}
                  </div>
                </div>
                <div>
                  <div className={'b-user-balance mr-2'}>
                    {item.user ? item.user.name : ''}
                  </div>
                </div>
              </div>
            )}
          />
        </Modal.Body>
      </Modal>

      <Folder
        noMessage
        show={showFolder}
        onHide={() => {
          dispatch(onSetLocalCategories([]))
          setShowFolder(false)
          setSelected(null)
        }}
        onAssign={item => {
          if (logType === 1 || access) {
            let alert = {
              show: true,
              title: '¿Desea añadir esta categoría?',
              type: 'info',
              confirmButtonColor: '#226095',
              onConfirm: () => {
                accessLog.categorization.push(item)
                setAccessLog({ ...accessLog })
                setShowFolder(false)
                setAlert({ show: false })
              },
              showCancelButton: true,
              confirmButtonText: 'Asignar',
              cancelButtonText: 'Cancelar',
              onCancel: () => setAlert({ show: false }),
            }
            setAlert({ ...alert })
          } else if (assign) assignCategorizations(item.id)
          else
            setAlert({
              ...handlerError('No tiene permiso de asignar o quitar una categoría'),
              onConfirm: () => setAlert({ ...alert, show: false }),
            })
        }}
        data2={
          categorization && categorization.children
            ? removeSelected(categorization.children[1])
            : {}
        }
        // data2={categorization && categorization.children ? categorization.children[1] : {}}
        loading={loadingAssigned}
        type={categoryType.CLIENTS}
      />

      <Alert {...alert} />
    </>
  )
}
export default ModalLog

export enum bankAccountPermissionsEnum {
  CreateAccount = 6161,
  EditAccount = 6167,
  DeleteAccount = 6162,
  ExportAccountMovementsCSV = 6163,
  CreateAccountMovementOperation,
  ImportAccountMovementsCSV = 6165,
  DeleteMovementTransaction = 6166,
  RequestMovementsTransactions = 6168,
  DuplicateOperations,
}
export {}

export const defaultBank: IBank = {
  countryId: 0,
  deleted: false,
  description: undefined,
  id: 37,
  label: '',
  name: '',
  value: 0,
}
export const defaultCurrency: Currency = {
  code: '',
  label: '',
  name: '',
  pluralName: '',
  symbolSpace: '',
  value: null,
  id: null,
  symbol: '',
}
export const defaultBankAccount: IBankAccount = {
  accountNumber: undefined,
  active: false,
  asigned: undefined,
  balance: 0.0,
  bank: defaultBank,
  company: 0,
  createdAt: undefined,
  createdBy: undefined,
  createdData: undefined,
  currency: defaultCurrency,
  deleted: false,
  deletedAt: undefined,
  deletedBy: 0,
  deletedData: undefined,
  disbursement: undefined,
  editable: undefined,
  id: undefined,
  kolo: false,
  module: 0,
  name: '',
  report: false,
  toName: '',
  transformation: undefined,
  type: undefined,
  updatedAt: undefined,
  updatedBy: undefined,
  updatedData: undefined,
}
export enum TableElementEnum {
  authorized = 'authorized',
  pending = 'pending',
  deposit = 'deposit',
}

export const types = [
  { id: 1, label: 'Monetaria', value: 1 },
  { id: 2, label: 'Ahorro', value: 2 },
]
export const bankEmpty: IBank = {
  countryId: 0,
  deleted: false,
  description: undefined,
  id: null,
  label: 'Sin seleccionar',
  name: '',
  value: null,
}
export const typeEmpty: Type = { id: null, label: 'Sin seleccionar' }
export const currencyEmpty: Currency = {
  code: '',
  name: '',
  pluralName: '',
  symbol: '',
  symbolSpace: '',
  value: null,
  id: null,
  label: 'Sin seleccionar',
}
export const defaultBankAccountForForm: IBankAccount = {
  accountNumber: 0,
  active: true,
  asigned: undefined,
  balance: 0,
  bank: bankEmpty,
  company: 0,
  createdAt: undefined,
  createdBy: undefined,
  createdData: undefined,
  currency: currencyEmpty,
  deleted: false,
  deletedAt: undefined,
  deletedBy: 0,
  deletedData: undefined,
  disbursement: undefined,
  editable: undefined,
  id: undefined,
  kolo: false,
  module: 0,
  name: '',
  report: false,
  toName: '',
  transformation: undefined,
  type: typeEmpty,
  updatedAt: undefined,
  updatedBy: undefined,
  updatedData: undefined,
}
export const operations = [
  { id: 100, label: 'Monto inicial' },
  { id: 101, label: 'Acreditacion' },
  { id: 102, label: 'Retiro' },
]

export const options = [
  { value: 101, label: 'Acreditar a la cuenta' },
  { value: 102, label: 'Retirar de la cuenta' },
  { value: 104, label: 'Transferir a efectivo circulante' },
  { value: 103, label: 'Transferencia entre cuentas' },
]

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Col, Row } from 'react-bootstrap'
import { Button, FormText, Select, Switch } from 'src/components'

import { actionTypes as typeT, createAccount } from 'src/actions/banks.actions'
import { selectGetAllCurrencies, selectGetBanks } from 'src/selectors/banks.selector'

import { selectorGetUsersSubmodule } from 'src/selectors/modules.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { showAlert } from 'src/actions/alert.actions'

import { defaultBankAccountForForm, types } from 'src/enums/bankEnums'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

interface IErrorProps {
  accountNumber?: string
  name?: string
  toName?: string
  bank?: string
  type?: string
  currency?: string
}

const AddAccountForm = ({ onHide }: AddAccountModalModalProps) => {
  const dispatch = useDispatch()

  const createdAccountFlag = useSelector(state =>
    loadingSelector([typeT.CREATE_ACCOUNT])(state),
  )
  const createdAccountError = useSelector(state =>
    hasErrors([typeT.CREATE_ACCOUNT])(state),
  )

  const banks = useSelector(selectGetBanks)
  const currencies = useSelector(selectGetAllCurrencies)
  const usersSubmodule = useSelector(state => selectorGetUsersSubmodule(state))

  const [flag, setFlag] = useState<boolean>(false)
  const [accountData, setAccountData] = useState<IBankAccount>(defaultBankAccountForForm)

  const [errors, setErrors] = useState<IErrorProps>({})

  useEffect(() => {
    if (createdAccountFlag) setFlag(true)
    else if (flag) {
      setFlag(false)

      const alert = createdAccountError
        ? handlerError(createdAccountError.message)
        : { ...handlerSuccess('Cuenta Creada'), onConfirm: () => onHide() }
      dispatch(showAlert(alert))
    }
  }, [createdAccountFlag])

  const handleAccountData = (value: string, id: string, isNumber?: boolean) => {
    if (isNumber) {
      value = value?.replace(/[^0-9]+/g, '')
    }

    setAccountData(prevData => ({
      ...prevData,
      [id]: value,
    }))
  }

  const addNewAccount = (e: React.FormEvent) => {
    e.preventDefault()

    const error: IErrorProps = {}
    if (!haveAnyValue(accountData.accountNumber)) error.accountNumber = 'Campo requerido'
    if (!haveAnyValue(accountData.name)) error.name = 'Campo requerido'
    if (!haveAnyValue(accountData.toName)) error.toName = 'Campo requerido'
    if (!haveAnyValue(accountData.bank?.value)) error.bank = 'Campo requerido'
    if (!haveAnyValue(accountData.type?.id)) error.type = 'Campo requerido'
    if (!haveAnyValue(accountData.currency?.value)) error.currency = 'Campo requerido'

    if (Object.keys(error).length > 0) setErrors(error)
    else {
      dispatch(
        createAccount(
          accountData.accountNumber,
          accountData.name,
          accountData.toName,
          accountData.bank.value,
          accountData.type.id,
          accountData.balance,
          8000,
          accountData.asigned,
          accountData.currency.value,
          accountData.report,
        ),
      )
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Nueva Cuenta </Modal.Title>
      </Modal.Header>

      <Modal.Body className={'pl-3 pr-3'} data-testid={'add-acount-form'}>
        <div data-testid={'add-acount-core'}>
          <Row>
            <Col xl={6} md={6} xs={12}>
              <FormText
                label={'Numero de Cuenta'}
                required
                data-testid="accountNumber"
                placeholder={'Numero'}
                type={'text'}
                name={'number'}
                value={accountData.accountNumber}
                changeValue={(value: string) =>
                  handleAccountData(value, 'accountNumber', true)
                }
                id="accountNumber"
                error={errors.accountNumber}
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <FormText
                label={'Alias'}
                required
                placeholder={'Alias'}
                data-testid={'name'}
                type={'text'}
                changeValue={(value: string) => handleAccountData(value, 'name')}
                value={accountData.name}
                id="name"
                error={errors.name}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6} md={6} xs={12}>
              <FormText
                label={'A nombre de'}
                placeholder={'Nombre del propietario'}
                required
                type={'text'}
                error={errors.toName}
                data-testid={'toName'}
                changeValue={(value: string) => handleAccountData(value, 'toName')}
                value={accountData.toName}
                id="toName"
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <Select
                label={'Banco'}
                options={banks}
                required
                dataCy={'accountBank'}
                onChange={bank => setAccountData({ ...accountData, bank })}
                id={'accountBank'}
                value={accountData.bank}
                testId="customSelect1"
                error={errors.bank}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Select
                label={'Tipo'}
                dataCy={'type'}
                required
                options={types}
                onChange={type => setAccountData({ ...accountData, type })}
                value={accountData.type}
                id={'type'}
                error={errors.type}
              />
            </Col>

            <Col>
              <Select
                required
                label={'Moneda'}
                options={currencies}
                onChange={currency => setAccountData({ ...accountData, currency })}
                value={accountData.currency}
                id={'currencies'}
                dataCy={'currencies'}
                error={errors.currency}
              />
            </Col>
            <Col>
              <Select
                label={'Encargado'}
                options={usersSubmodule}
                onChange={data => setAccountData({ ...accountData, asigned: data })}
                value={accountData.asigned}
                id={'subModule'}
                dataCy={'subModule'}
              />
            </Col>

            <Col>
              <FormText
                label={'Balance'}
                type={'text'}
                data-testid={'balance'}
                changeValue={(value: string) => handleAccountData(value, 'balance', true)}
                placeholder={'0.0'}
                id={'balance'}
                name={'balance'}
                value={accountData.balance}
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={'mr-3'}>
          <Switch
            name={'report'}
            checked={accountData.report}
            dataCy={'shouldAccount'}
            id={'reportAccount'}
            onChange={() =>
              setAccountData({ ...accountData, report: !accountData.report })
            }
            label={'Afectar reporte patrimonial'}
            info={
              'Al activar esta opción, las actualizaciones sobre los saldos de esta cuenta se reflejarán en el reporte patrimonial.'
            }
            placement={'top'}
          />
        </div>
        <div>
          <Button
            onClick={addNewAccount}
            dataCy={'create-account'}
            data-testid={'create-account'}
            id={'create-account'}
            style={{ maxWidth: '100%' }}
            loading={createdAccountFlag}>
            Crear
          </Button>
        </div>
      </Modal.Footer>
    </>
  )
}

const AddAccountModal: React.FC<AddAccountModalModalProps> = ({
  show,
  onHide,
}: AddAccountModalModalProps) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <AddAccountForm show={show} onHide={onHide} data-testid="account-add-form" />
    </Modal>
  )
}

export { AddAccountModal, AddAccountForm }

import { identificationTypesEnum } from 'src/enums/identificationTypes'
import { actionTypes } from '../actions/clients.actions'
import { extract, successState } from '../actions/global.actions'

const initialState = {
  genericResponse: {},
  currentClient: {},
  allClients: [],
  totalClients: 0,
  clientSimple: [],
  allCotizapClients: [],
  someCotizapClients: [],
  selectedClients: [],
  deliverers: [],
  sellers: [],
  sellersP: [],
  uploadResponse: {},
  company: '',
  global: {},
  create: { images: [], auto: true },
  requestsInfo: [],
  historical: {
    initial: 0,
    last: 0,
  },
  historicalLog: [],
  totalHistoricalLog: 0,
  report: {},
  reports: [],
  archived: [],
  hideAction: '',
  log: [],
  dirty: '',
  statusReport: [],
  balanceReport: [],
  requests: [],
  request: {},
  totalLog: 0,
  viewers: [],
  simpleLog: {},
  vouchers: [],
  clients: [],
  transactions: {},
  products: [],
  clientsInsidePolygon: [],
  infoNIT: {},
  contacts: [],
  infoCUI: {},
  identificationsInfo: {},
  vouchersToPayment: {
    totalVoucher: 0,
    vouchers: [],
  },
}

const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_CONTACTS):
      return { ...state, contacts: extract(action.contacts, []) }
    case successState(actionTypes.GET_ALL_CLIENTS):
      // allClients: action.clients ? action.clients.list : [],
      //                 totalClients: action.clients ? action.clients.total : 0
      return {
        ...state,
        allClients: extract(action.response ? action.response.list : [], []),
        totalClients: extract(action.response ? action.response.total : 0, 0),
      }
    case successState(actionTypes.GET_INSIDE_IN_POLYGON):
      return { ...state, clientsInsidePolygon: extract(action.response, []) }
    case successState(actionTypes.GET_USERS_VIEWERS):
      return { ...state, viewers: extract(action.response, []) }
    case successState(actionTypes.VALIDATE_INFORMATION):
      return { ...state, dirty: extract(action.dirty, '') }
    case successState(actionTypes.HIDE_ACTION):
      return { ...state, hideAction: extract(action.hideAction, '') }
    case successState(actionTypes.GET_ARCHIVED):
      return { ...state, archived: extract(action.archived, []) }
    case successState(actionTypes.GET_HISTORICAL_LOG):
      return {
        ...state,
        historicalLog: action.historicalLog ? action.historicalLog.list : [],
        totalHistoricalLog: action.historicalLog ? action.historicalLog.total : 0,
      }
    case successState(actionTypes.GET_HISTORICAL_PAYMENT):
      return {
        ...state,
        historical: extract(action.historical, { initial: 0, last: 0 }),
      }
    case successState(actionTypes.CLIENT_LOG):
      return { ...state, log: extract(action.log, []) }
    case successState(actionTypes.REPORT_LOG):
      return {
        ...state,
        log: extract(action.log.list, []),
        totalLog: action.log.total,
      }
    case successState(actionTypes.GET_REPORT_LOG):
      return { ...state, reports: extract(action.report, []) }
    case successState(actionTypes.STATUS_REPORT):
      return { ...state, statusReport: extract(action.report, []) }
    case successState(actionTypes.GET_BALANCE_REPORT):
      return { ...state, balanceReport: extract(action.report, []) }
    case successState(actionTypes.GET_CLIENT_REQUESTS):
      return { ...state, requests: extract(action.response, []) }
    case successState(actionTypes.GET_CLIENT_REQUEST):
      return { ...state, request: extract(action.response, []) }
    case successState(actionTypes.GET_CLIENT_POLYGON):
      return {
        ...state,
        clientSimple: extract(action.allClients, []),
      }
    case successState(actionTypes.GET_INFO_REQUEST):
      return {
        ...state,
        requestsInfo: extract(action.requests, []),
      }
    case actionTypes.CREATE_SINGLE_CLIENT:
      return {
        ...state,
        create: { ...action.client },
      }
    case successState(actionTypes.GLOBAL_CLIENT):
      return {
        ...state,
        global: extract(action.response, {}),
      }
    case successState(actionTypes.ON_CREATE_COTIZAP_COMPANY_BY_CLIENT):
      return {
        ...state,
        company: extract(action.company, ''),
      }
    case successState(actionTypes.GET_ALL_SELLERS_PRODUCER):
      return {
        ...state,
        sellersP: extract(action.sellersP, []),
        sellers: extract(action.sellersP, []),
      }
    case successState(actionTypes.GET_ALL_DELIVERERS):
      return {
        ...state,
        deliverers: extract(action.deliverers, []),
      }
    case successState(actionTypes.GET_ALL_SELLERS):
      return {
        ...state,
        sellers: extract(action.sellers, []),
      }
    case successState(actionTypes.SIMPLE_LOG):
      return {
        ...state,
        simpleLog: extract(action.simpleLog, {}),
      }
    case successState(actionTypes.UPDATE_MULTIPLE_CLIENTS):
      return {
        ...state,
        genericResponse: extract(action.genericResponse, []),
      }
    case actionTypes.GET_SINGLE_CLIENTS_SUCCESS:
      return {
        ...state,
        currentClient: action.clients,
      }
    case actionTypes.CREATE_SINGLE_CLIENTS_SUCCESS:
      return {
        ...state,
        currentClient: action.clients,
      }
    case actionTypes.SET_ALL_COTIZAP_CLIENTS:
      return {
        ...state,
        allClients: action.val,
      }
    case actionTypes.GET_ALL_COTIZAP_CLIENTS_SUCCESS:
      return {
        ...state,
        allCotizapClients: action.clients,
      }
    case actionTypes.GET_SOME_COTIZAP_CLIENTS_SUCCESS:
      return {
        ...state,
        someCotizapClients: action.clients,
      }
    case actionTypes.GET_SELECTED_CLIENTS:
      return {
        ...state,
        selected: action.val,
      }
    case actionTypes.SET_SELECTED_CLIENTS:
      return {
        ...state,
        selectedClients: action.val,
      }
    case actionTypes.UPLOAD_CSV_SUCCESS:
      return {
        ...state,
        uploadResponse: action.response,
      }
    case actionTypes.UPLOAD_CSV:
      return {
        ...state,
        uploadResponse: action.response,
      }
    case successState(actionTypes.GET_VOUCHERS_CLIENT_TO_PAYMENT):
      return {
        ...state,
        vouchersToPayment: action.vouchers,
      }
    case successState(actionTypes.GET_VOUCHERS_CLIENT):
      return {
        ...state,
        vouchers: action.vouchers,
      }
    case successState(actionTypes.GET_CLIENTS_WITH_VOUCHERS):
      return {
        ...state,
        clients: action.clients,
      }
    case successState(actionTypes.GET_REPORT):
      return {
        ...state,
        report: action.report,
      }
    case successState(actionTypes.GET_TRANSACTIONS):
      return {
        ...state,
        transactions: action.transactions,
      }
    case successState(actionTypes.GET_PRODUCTS_CLIENT):
      return {
        ...state,
        products: action.products,
      }
    case successState(actionTypes.GET_NIT_INFO): {
      const identificationsInfo = { ...state.identificationsInfo }
      identificationsInfo[identificationTypesEnum.NIT_GT] = {
        ...action.info,
        ...action.info.clientInfo,
      }

      return {
        ...state,
        infoNIT: action.info,
        identificationsInfo,
      }
    }
    case successState(actionTypes.GET_CUI_INFO): {
      const identificationsInfo = { ...state.identificationsInfo }
      identificationsInfo[identificationTypesEnum.CUI] = {
        ...action.info,
        ...action.info.clientInfo,
      }

      return {
        ...state,
        infoCUI: action.info,
        identificationsInfo,
      }
    }
    default:
      return state ? state : initialState
  }
}

export default ClientReducer

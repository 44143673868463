import ClientsService from '../services/clients.service'
import { executeAction } from './global.actions'
import UploadService from '../services/uploads.services'
import { imageTypes } from '../components/gallery/Gallery'
import categorizationsService from '../services/categorizations.service'
import { getState } from 'src/utils/utilities'
import { selectSimpleClients } from 'src/selectors/clients.selector'

export const actionTypes = {
  // COMMON

  GET_ALL_DELIVERERS: 'GET_ALL_DELIVERERS',
  GET_ALL_SELLERS: 'GET_ALL_SELLERS',

  GET_ALL_DELIVERERS_PRODUCER: 'GET_ALL_DELIVERERS_PRODUCER',
  GET_ALL_SELLERS_PRODUCER: 'GET_ALL_SELLERS_PRODUCER',

  //CLIENTS
  GET_ALL_CLIENTS: 'GET_ALL_CLIENTS',
  GET_ALL_CLIENTS_REQUEST: 'GET_ALL_CLIENTS_REQUEST',
  GET_ALL_CLIENTS_SUCCESS: 'GET_ALL_CLIENTS_SUCCESS',
  GET_ALL_CLIENTS_ERROR: 'GET_ALL_CLIENTS_ERROR',

  GET_SINGLE_CLIENTS: 'GET_SINGLE_CLIENTS',
  GET_SINGLE_CLIENTS_REQUEST: 'GET_SINGLE_CLIENTS_REQUEST',
  GET_SINGLE_CLIENTS_SUCCESS: 'GET_SINGLE_CLIENTS_SUCCESS',
  GET_SINGLE_CLIENTS_ERROR: 'GET_SINGLE_CLIENTS_ERROR',

  CREATE_SINGLE_CLIENTS: 'CREATE_SINGLE_CLIENTS',
  CREATE_SINGLE_CLIENTS_REQUEST: 'CREATE_SINGLE_CLIENTS_REQUEST',
  CREATE_SINGLE_CLIENTS_SUCCESS: 'CREATE_SINGLE_CLIENTS_SUCCESS',
  CREATE_SINGLE_CLIENTS_ERROR: 'CREATE_SINGLE_CLIENTS_ERROR',

  UPDATE_SINGLE_CLIENTS: 'UPDATE_SINGLE_CLIENTS',

  UPDATE_MULTIPLE_CLIENTS: 'UPDATE_MULTIPLE_CLIENTS',

  UPLOAD_CSV: 'UPLOAD_CSV',
  UPLOAD_CSV_REQUEST: 'UPLOAD_CSV_REQUEST',
  UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
  UPLOAD_CSV_ERROR: 'UPLOAD_CSV_ERROR',

  // GLOBAL CLIENTS
  SET_ALL_COTIZAP_CLIENTS: 'SET_ALL_COTIZAP_CLIENTS',

  GET_ALL_COTIZAP_CLIENTS: 'GET_ALL_COTIZAP_CLIENTS',
  GET_ALL_COTIZAP_CLIENTS_REQUEST: 'GET_ALL_COTIZAP_CLIENTS_REQUEST',
  GET_ALL_COTIZAP_CLIENTS_SUCCESS: 'GET_ALL_COTIZAP_CLIENTS_SUCCESS',
  GET_ALL_COTIZAP_CLIENTS_ERROR: 'GET_ALL_COTIZAP_CLIENTS_ERROR',

  GET_SOME_COTIZAP_CLIENTS: 'GET_SOME_COTIZAP_CLIENTS',
  GET_SOME_COTIZAP_CLIENTS_REQUEST: 'GET_SOME_COTIZAP_CLIENTS_REQUEST',
  GET_SOME_COTIZAP_CLIENTS_SUCCESS: 'GET_SOME_COTIZAP_CLIENTS_SUCCESS',
  GET_SOME_COTIZAP_CLIENTS_ERROR: 'GET_SOME_COTIZAP_CLIENTS_ERROR',

  SET_SELECTED_CLIENTS: 'SET_SELECTED_CLIENTS',
  GET_SELECTED_CLIENTS: 'GET_SELECTED_CLIENTS',

  GLOBAL_CLIENT: 'GLOBAL_CLIENT',

  ON_CREATE_COTIZAP_COMPANY_BY_CLIENT: 'ON_CREATE_COTIZAP_COMPANY_BY_CLIENT',

  CREATE_SINGLE_CLIENT: 'CREATE_SINGLE_CLIENT',

  ON_INFO_REQUEST: 'ON_INFO_REQUEST',
  GET_INFO_REQUEST: 'GET_INFO_REQUEST',
  ON_INFO_REQUEST_RESPONSES: 'ON_INFO_REQUEST_RESPONSES',

  GET_CLIENT_POLYGON: 'GET_CLIENT_POLYGON',

  GET_CAPTCHA_RESPONSE: 'GET_CAPTCHA_RESPONSE',

  DELETE_CLIENT: 'DELETE_CLIENT',

  GET_HISTORICAL_PAYMENT: 'GET_HISTORICAL_PAYMENT',
  GET_HISTORICAL_LOG: 'GET_HISTORICAL_LOG',
  CHANGE_STATE: 'CHANGE_STATE',
  GET_REPORT: 'GET_REPORT',
  ON_DEFAULTER: 'ON_DEFAULTER',
  SET_CLIENT_VIEWERS: 'SET_CLIENT_VIEWERS',
  HIDE_ACTION: 'HIDE_ACTION',
  CLIENT_LOG: 'CLIENT_LOG',
  GET_ARCHIVED: 'GET_ARCHIVED',
  VALIDATE_INFORMATION: 'VALIDATE_INFORMATION',
  CREATE_LOG: 'CREATE_LOG',
  GET_REPORT_LOG: 'GET_REPORT_LOG',
  MERGE_CLIENTS: 'MERGE_CLIENTS',
  STATUS_REPORT: 'STATUS_REPORT',
  GET_BALANCE_REPORT: 'GET_BALANCE_REPORT',
  CREATE_CLIENT_REQUESTS: 'CREATE_CLIENT_REQUESTS',
  GET_CLIENT_REQUESTS: 'GET_CLIENT_REQUESTS',
  GET_CLIENT_REQUEST: 'GET_CLIENT_REQUEST',
  UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
  DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',
  UPDATE_L0G: 'UPDATE_L0G',
  GET_USERS_VIEWERS: 'GET_USERS_VIEWERS',
  SET_CLIENT_VIEWER: 'SET_CLIENT_VIEWER',
  REPORT_LOG: 'REPORT_LOG',
  REPORT_LOG_EXCEL: 'REPORT_LOG_EXCEL',
  SIMPLE_LOG: 'SIMPLE_LOG',
  GET_VOUCHERS_CLIENT: 'GET_VOUCHERS_CLIENT',
  GET_VOUCHERS_CLIENT_TO_PAYMENT: 'GET_VOUCHERS_CLIENT_TO_PAYMENT',
  GET_CLIENTS_WITH_VOUCHERS: 'GET_CLIENTS_WITH_VOUCHERS',
  SEND_CLIENT_EMAIL: 'SEND_CLIENT_EMAIL',
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  DOWNLOAD_EXCEL_TRANSACTIONS: 'DOWNLOAD_EXCEL_TRANSACTIONS',
  GET_PRODUCTS_CLIENT: 'GET_PRODUCTS_CLIENT',
  GET_EXCEL_PRODUCTS: 'GET_EXCEL_PRODUCTS',
  GET_NIT_INFO: 'GET_NIT_INFO',
  GET_INSIDE_IN_POLYGON: 'GET_INSIDE_IN_POLYGON',
  ASSIGN_CATEGORIES_TO_MULTIPLE_CLIENTS: 'ASSIGN_CATEGORIES_TO_MULTIPLE_CLIENTS',
  DELETE_CONTACT: 'DELETE_CONTACT',
  GET_CONTACTS: 'GET_CONTACTS',
  GET_CUI_INFO: 'GET_CUI_INFO',
  LOCK_CLIENT: 'LOCK_CLIENT',
  UNLOCK_CLIENT: 'UNLOCK_CLIENT',
  GET_CREDIT_NOTE_EXCEL: 'GET_CREDIT_NOTE_EXCEL',
}

const getAllClientsRequest = () => ({
  type: actionTypes.GET_ALL_CLIENTS_REQUEST,
})
const getAllClientsSuccess = clients => ({
  type: actionTypes.GET_ALL_CLIENTS_SUCCESS,
  clients,
})
const getAllClientsError = error => ({
  type: actionTypes.GET_ALL_CLIENTS_ERROR,
  error,
})

const getSingleClientRequest = () => ({
  type: actionTypes.GET_SINGLE_CLIENTS_REQUEST,
})
const getSingleClientSuccess = clients => ({
  type: actionTypes.GET_SINGLE_CLIENTS_SUCCESS,
  clients,
})
const getSingleClientError = error => ({
  type: actionTypes.GET_SINGLE_CLIENTS_ERROR,
  error,
})

const onCreateClient = client => ({
  type: actionTypes.CREATE_SINGLE_CLIENT,
  client,
})

const createSingleClientRequest = () => ({
  type: actionTypes.CREATE_SINGLE_CLIENTS_REQUEST,
})
const createSingleClientSuccess = clients => ({
  type: actionTypes.CREATE_SINGLE_CLIENTS_SUCCESS,
  clients,
})
const createSingleClientError = error => ({
  type: actionTypes.CREATE_SINGLE_CLIENTS_ERROR,
  error,
})

// global clients
const setAllCotizapClientsI = val => ({
  type: actionTypes.SET_ALL_COTIZAP_CLIENTS,
  val,
})
const getAllCotizapClientsRequest = () => ({
  type: actionTypes.GET_ALL_COTIZAP_CLIENTS_REQUEST,
})
const getAllCotizapClientsSuccess = clients => ({
  type: actionTypes.GET_ALL_COTIZAP_CLIENTS_SUCCESS,
  clients,
})
const getAllCotizapClientsError = error => ({
  type: actionTypes.GET_ALL_COTIZAP_CLIENTS_ERROR,
  error,
})

const getSomeCotizapClientsRequest = () => ({
  type: actionTypes.GET_SOME_COTIZAP_CLIENTS_REQUEST,
})
const getSomeCotizapClientsSuccess = clients => ({
  type: actionTypes.GET_SOME_COTIZAP_CLIENTS_SUCCESS,
  clients,
})
const getSomeCotizapClientsError = error => ({
  type: actionTypes.GET_SOME_COTIZAP_CLIENTS_ERROR,
  error,
})

const getSelectedClientsI = () => ({ type: actionTypes.GET_SELECTED_CLIENTS })
const setSelectedClientsI = val => ({
  type: actionTypes.SET_SELECTED_CLIENTS,
  val,
})

export const onCreateClientStep = client => dispatch => dispatch(onCreateClient(client))
const uploadCSVRequest = () => ({ type: actionTypes.UPLOAD_CSV_REQUEST })
const uploadCSVSuccess = response => ({
  type: actionTypes.UPLOAD_CSV_SUCCESS,
  response,
})
const uploadCSVError = error => ({
  type: actionTypes.UPLOAD_CSV_ERROR,
  error,
})

export const onUpdateGlobalClient = (request, id) => dispatch => {
  const process = async () => ({
    response: await ClientsService.onUpdateGlobalClient(request, id),
  })
  dispatch(executeAction(actionTypes.GLOBAL_CLIENT, process))
}

export const getGlobalClient = id => dispatch => {
  const process = async () => ({
    response: await ClientsService.getGlobalClient(id),
  })
  dispatch(executeAction(actionTypes.GLOBAL_CLIENT, process))
}

export const onCreateGlobalClient = request => dispatch => {
  const process = async () => ({
    response: await ClientsService.onCreateGlobalClient(request),
  })
  dispatch(executeAction(actionTypes.GLOBAL_CLIENT, process))
}

export const getAllDeliverersByProducer = () => dispatch => {
  const process = async () => ({
    deliverers: await ClientsService.getDeliverersByProducer(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_DELIVERERS, process))
}

export const getAllSellersByProducer = () => dispatch => {
  const process = async () => ({
    sellersP: await ClientsService.getSellersByProducer(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_SELLERS_PRODUCER, process))
}

export const getAllDeliverers = role => dispatch => {
  const process = async () => ({
    deliverers: await ClientsService.getDeliverers(role),
  })
  dispatch(executeAction(actionTypes.GET_ALL_DELIVERERS, process))
}

export const getAllSellers = role => dispatch => {
  const process = async () => ({
    sellers: await ClientsService.getSellers(role),
  })
  dispatch(executeAction(actionTypes.GET_ALL_SELLERS, process))
}

export const updateMultipleClients = request => dispatch => {
  const process = async () => ({
    genericResponse: await ClientsService.updateMultipleClients(request),
  })
  dispatch(executeAction(actionTypes.UPDATE_MULTIPLE_CLIENTS, process))
}

export const updateClient = (id, request) => async dispatch => {
  const process = async () => await ClientsService.updateClient(id, request)
  dispatch(executeAction(actionTypes.UPDATE_SINGLE_CLIENTS, process))
}

// DEPRECATED: No usar mas
export const getAllClients =
  (
    attendant,
    stateId,
    dirty,
    cpc,
    sort,
    skip,
    size,
    search,
    start,
    end,
    userId,
    visit,
    categorization,
    log,
    clientsId,
  ) =>
  async dispatch => {
    dispatch(getAllClientsRequest())
    try {
      const allClients = await ClientsService.getAllClients(
        {
          attendant,
          stateId,
          dirty,
          cpc,
          sort,
          skip,
          size,
          search,
          start,
          end,
          userId,
          visit,
          categorization,
          log,
        },
        clientsId,
      )
      dispatch(getAllClientsSuccess(allClients))
    } catch (error) {
      dispatch(getAllClientsError(error))
    }
  }

export const getAllClients2 = (params, clientsId) => async dispatch => {
  const process = async () => ({
    response: await ClientsService.getAllClients(params, clientsId),
  })
  dispatch(executeAction(actionTypes.GET_ALL_CLIENTS, process))
}

export const getSingleClient = id => async dispatch => {
  dispatch(getSingleClientRequest())
  try {
    const currentClient = await ClientsService.getSingleClient(id)
    dispatch(getSingleClientSuccess(currentClient))
  } catch (error) {
    dispatch(getSingleClientError(error))
  }
}

export const createClient = (request, images, categorization) => async dispatch => {
  dispatch(createSingleClientRequest())
  try {
    const newClient = await ClientsService.createClient(request)
    if (images !== undefined && images.length > 0) {
      await UploadService.addImages(imageTypes.CLIENT.id, newClient.id, images)
    }
    if (categorization && categorization.length > 0) {
      const promises = categorization.map(c =>
        categorizationsService.assignEntity('clients', c.id, newClient.id, false),
      )
      Promise.all(promises)
    }

    dispatch(createSingleClientSuccess(newClient))
  } catch (error) {
    dispatch(createSingleClientError(error))
  }
}

export const setAllCotizapClients = val => dispatch => {
  dispatch(setAllCotizapClientsI(val))
}

export const getAllCotizapClients = () => async dispatch => {
  dispatch(getAllCotizapClientsRequest())
  try {
    const allCotizapClients = await ClientsService.getAllCotizapClients()
    dispatch(getAllCotizapClientsSuccess(allCotizapClients))
  } catch (error) {
    dispatch(getAllCotizapClientsError(error))
  }
}

export const getSomeCotizapClients = query => async dispatch => {
  dispatch(getSomeCotizapClientsRequest())
  try {
    const someCotizapClients = await ClientsService.getSomeCotizapClients(query)
    dispatch(getSomeCotizapClientsSuccess(someCotizapClients))
  } catch (error) {
    dispatch(getSomeCotizapClientsError(error))
  }
}

export const getSelectedClients = () => dispatch => {
  dispatch(getSelectedClientsI())
}

export const setSelectedClients = val => dispatch => {
  dispatch(setSelectedClientsI(val))
}

export const onCreateCompanyWithClient = company => dispatch => {
  const process = async () => ({
    company: await ClientsService.onCreateCompanyWithClient(company),
  })
  dispatch(executeAction(actionTypes.ON_CREATE_COTIZAP_COMPANY_BY_CLIENT, process))
}

export const searchCotizapClient = code => dispatch => {
  const process = async () => ({
    response: await ClientsService.searchCotizapClient(code),
  })
  dispatch(executeAction(actionTypes.GLOBAL_CLIENT, process))
}

export const loadClientsCSV = (file, global, onSuccess) => async dispatch => {
  dispatch(uploadCSVRequest())
  try {
    const response = await ClientsService.loadClientsCSV(file, global)
    dispatch(uploadCSVSuccess(response))
    onSuccess(response, false)
  } catch (e) {
    console.log(e)
    onSuccess(e, true)
    dispatch(uploadCSVError(e))
  }
}

export const massiveLoadClients = (data, currentIndex, onSuccess) => async dispatch => {
  const response = await ClientsService.loadClients(data, currentIndex)
  const process = async () => ({
    response,
  })
  dispatch(executeAction(actionTypes.UPLOAD_CSV, process))
  onSuccess(response, false)
}

export const massiveLoadClientsSv = (data, currentIndex, onSuccess) => async dispatch => {
  const response = await ClientsService.loadClientsSv(data, currentIndex)
  const process = async () => ({
    response,
  })
  dispatch(executeAction(actionTypes.UPLOAD_CSV, process))
  onSuccess(response, false)
}

export const onRequestInfo = clients => dispatch => {
  const process = async () => ({
    request: await ClientsService.onRequestInfo(clients),
  })
  dispatch(executeAction(actionTypes.ON_INFO_REQUEST, process))
}

export const getRequestInfo = client => dispatch => {
  const process = async () => ({
    requests: await ClientsService.getRequestInfo(client),
  })
  dispatch(executeAction(actionTypes.GET_INFO_REQUEST, process))
}

export const onRequestResponse = (clientId, status, requestedId) => dispatch => {
  const process = async () => ({
    responses: await ClientsService.onRequestResponse(clientId, status, requestedId),
  })
  dispatch(executeAction(actionTypes.ON_INFO_REQUEST_RESPONSES, process))
}

export const getClientsByPolygonTransport = (deliverer, vendor, polygon) => dispatch => {
  const process = async () => ({
    allClients: await ClientsService.getClientsByPolygonTransport(
      deliverer || 0,
      vendor || 0,
      polygon || 0,
    ),
  })
  dispatch(executeAction(actionTypes.GET_CLIENT_POLYGON, process))
}

export const softDelete = id => dispatch => {
  const process = async () => ({
    response: await ClientsService.softDelete(id),
  })
  dispatch(executeAction(actionTypes.DELETE_CLIENT, process))
}

export const getCaptchaResponse = secretKey => dispatch => {
  const process = async () => ({
    response: await ClientsService.getCaptchaResponse(secretKey),
  })
  dispatch(executeAction(actionTypes.GET_CLIENT_POLYGON, process))
}

export const getHistoricalPayments = (start, end, clientId, type) => dispatch => {
  const process = async () => ({
    historical: await ClientsService.getHistoricalPayments({
      start,
      end,
      clientId,
      type,
    }),
  })
  dispatch(executeAction(actionTypes.GET_HISTORICAL_PAYMENT, process))
}

export const getHistoricalLog =
  (start, end, clientId, type, skip, search, size, providerId, onlyPayments) =>
  dispatch => {
    const process = async () => ({
      historicalLog: await ClientsService.getHistoricalLog({
        start,
        end,
        clientId,
        type,
        skip,
        search,
        size,
        providerId,
        onlyPayments,
      }),
    })
    dispatch(executeAction(actionTypes.GET_HISTORICAL_LOG, process))
  }

export const changeStatus = clientId => dispatch => {
  const process = async () => await ClientsService.changeStatus(clientId)
  dispatch(executeAction(actionTypes.CHANGE_STATE, process))
}

export const getReport = (clientId, start, end) => dispatch => {
  const process = async () => ({
    report: await ClientsService.getReport(clientId, start, end),
  })
  dispatch(executeAction(actionTypes.GET_REPORT, process))
}

export const onChangeDefaulterStatus = clientId => dispatch => {
  const process = async () => ({
    defaulter: await ClientsService.onChangeDefaulterStatus(clientId),
  })
  dispatch(executeAction(actionTypes.ON_DEFAULTER, process))
}

export const getClientsSimple = params => dispatch => {
  const clients = getState(selectSimpleClients)
  if (clients.length > 0 && !params?.force) return

  const process = async () => ({
    allClients: await ClientsService.getClientsSimple(params),
  })
  dispatch(executeAction(actionTypes.GET_CLIENT_POLYGON, process))
}

export const setClientViewersUsers = (request, params) => dispatch => {
  const process = async () => ({
    viewers: await ClientsService.setClientViewersUsers(request, params),
  })
  dispatch(executeAction(actionTypes.SET_CLIENT_VIEWERS, process))
}

export const setClientViewersUser = request => dispatch => {
  const process = async () => await ClientsService.setClientViewersUsers(request)
  dispatch(executeAction(actionTypes.SET_CLIENT_VIEWER, process))
}

export const getArchivedClients = () => dispatch => {
  const process = async () => ({
    archived: await ClientsService.getArchivedClients(),
  })
  dispatch(executeAction(actionTypes.GET_ARCHIVED, process))
}

export const onHideClients = (type, clients) => dispatch => {
  const process = async () => ({
    hideAction: await ClientsService.onHideClients(type, clients),
  })
  dispatch(executeAction(actionTypes.HIDE_ACTION, process))
}

export const getClientLog = (type, logType, referenceType, noSecured) => dispatch => {
  const process = async () => ({
    log: await ClientsService.getClientLog(type, logType, referenceType, noSecured),
  })
  dispatch(executeAction(actionTypes.CLIENT_LOG, process))
}

export const getSimpleLog = id => dispatch => {
  const process = async () => ({
    simpleLog: await ClientsService.getSimpleLog(id),
  })
  dispatch(executeAction(actionTypes.SIMPLE_LOG, process))
}

export const getLogReport =
  (request, start, end, skip, size, search, refType) => dispatch => {
    const process = async () => ({
      log: await ClientsService.getLogReport(
        request,
        start,
        end,
        skip,
        size,
        search,
        refType,
      ),
    })
    dispatch(executeAction(actionTypes.REPORT_LOG, process))
  }

export const onUpdateDirtyClient = (clientIds, params) => dispatch => {
  const process = async () => ({
    dirty: await ClientsService.onUpdateDirtyClient(clientIds, params),
  })
  dispatch(executeAction(actionTypes.VALIDATE_INFORMATION, process))
}

export const createLog =
  (client, description, logType, checked, categorizations) => dispatch => {
    const process = async () =>
      await ClientsService.createClientLog(
        client,
        description,
        logType,
        checked,
        categorizations,
      )
    dispatch(executeAction(actionTypes.CREATE_LOG, process))
  }

export const getReportLog = (sDate, eDate) => dispatch => {
  const process = async () => ({
    report: await ClientsService.getReportLog(sDate, eDate),
  })
  dispatch(executeAction(actionTypes.GET_REPORT_LOG, process))
}

export const mergeClients = (pivot, list) => dispatch => {
  const process = async () => await ClientsService.mergeClients(pivot, list)
  dispatch(executeAction(actionTypes.MERGE_CLIENTS, process))
}

export const clientStatusReport = requests => dispatch => {
  const process = async () => ({
    report: await ClientsService.clientStatusReport(requests),
  })
  dispatch(executeAction(actionTypes.STATUS_REPORT, process))
}

export const getBalanceReport = params => dispatch => {
  const process = async () => ({
    report: await ClientsService.getBalanceReport(params),
  })
  dispatch(executeAction(actionTypes.GET_BALANCE_REPORT, process))
}

export const getAllRequests = params => dispatch => {
  const process = async () => ({
    response: await ClientsService.getAllRequests(params),
  })
  dispatch(executeAction(actionTypes.GET_CLIENT_REQUESTS, process))
}

export const getClientRequest = id => dispatch => {
  const process = async () => ({
    response: await ClientsService.getClientRequest(id),
  })
  dispatch(executeAction(actionTypes.GET_CLIENT_REQUEST, process))
}

export const updateStatus = (id, status) => dispatch => {
  const process = async () => ({
    response: await ClientsService.updateStatusRequest(id, status),
  })
  dispatch(executeAction(actionTypes.UPDATE_CLIENT_REQUEST, process))
}

export const createClientEditRequest = request => dispatch => {
  const process = async () => ({
    response: await ClientsService.createClientEditRequest(request),
  })
  dispatch(executeAction(actionTypes.CREATE_CLIENT_REQUESTS, process))
}

export const downloadExcel = (params, clientsId) => dispatch => {
  const process = async () => ({
    response: await ClientsService.downloadExcel(params, clientsId),
  })
  dispatch(executeAction(actionTypes.DOWNLOAD_EXCEL, process))
}

export const updateItemLog = (id, status) => dispatch => {
  const process = async () => await ClientsService.updateItemLog(id, status)
  dispatch(executeAction(actionTypes.UPDATE_L0G, process))
}

export const getClientViewersByClientId = clientId => dispatch => {
  const process = async () => ({
    response: await ClientsService.getClientViewersByClientId(clientId),
  })
  dispatch(executeAction(actionTypes.GET_USERS_VIEWERS, process))
}

export const getVouchersByCustomers = params => dispatch => {
  const process = async () => ({
    vouchers: await ClientsService.getVouchersClient(params),
  })
  dispatch(executeAction(actionTypes.GET_VOUCHERS_CLIENT, process))
}

export const getVouchersByCustomersToPayment = params => dispatch => {
  const process = async () => ({
    vouchers: await ClientsService.getVouchersClient(params),
  })
  dispatch(executeAction(actionTypes.GET_VOUCHERS_CLIENT_TO_PAYMENT, process))
}

export const getClientsWithVouchers = () => dispatch => {
  const process = async () => ({
    clients: await ClientsService.getClientsWithVouchers(),
  })
  dispatch(executeAction(actionTypes.GET_CLIENTS_WITH_VOUCHERS, process))
}

export const sendEmail = (request, all) => dispatch => {
  const process = async () => ({
    response: await ClientsService.sendEmail(request, all),
  })
  dispatch(executeAction(actionTypes.SEND_CLIENT_EMAIL, process))
}

export const getTransactionClient = (params, id) => dispatch => {
  const process = async () => ({
    transactions: await ClientsService.getTransactionsClient(params, id),
  })
  dispatch(executeAction(actionTypes.GET_TRANSACTIONS, process))
}

export const downloadTransactionsExcel = (request, params) => dispatch => {
  const process = async () => ({
    response: await ClientsService.downloadTransactionsExcel(request, params),
  })
  dispatch(executeAction(actionTypes.DOWNLOAD_EXCEL_TRANSACTIONS, process))
}

export const getProductsClient = (id, params) => dispatch => {
  const process = async () => ({
    products: await ClientsService.getProductsClient(id, params),
  })
  dispatch(executeAction(actionTypes.GET_PRODUCTS_CLIENT, process))
}

export const downloadProductsExcel = (id, params) => dispatch => {
  const process = async () => ({
    response: await ClientsService.downloadProductsExcel(id, params),
  })
  dispatch(executeAction(actionTypes.GET_EXCEL_PRODUCTS, process))
}

export const downloadLogExcel = (request, params) => dispatch => {
  const process = async () => ({
    response: await ClientsService.getLogExcelReport(request, params),
  })
  dispatch(executeAction(actionTypes.REPORT_LOG_EXCEL, process))
}

export const getInfoNIT = (nit, signal) => dispatch => {
  const process = async () => ({
    info: await ClientsService.getClientByIdentification({ nit }, signal),
  })
  return dispatch(executeAction(actionTypes.GET_NIT_INFO, process))
}

export const getInfoCUI = (cui, signal) => dispatch => {
  const process = async () => ({
    info: await ClientsService.getClientByIdentification({ cui }, signal),
  })
  return dispatch(executeAction(actionTypes.GET_CUI_INFO, process))
}

export const getClientsInsidePolygon = points => dispatch => {
  const process = async () => ({
    response: await ClientsService.findClientByPolygonPoints(points),
  })
  dispatch(executeAction(actionTypes.GET_INSIDE_IN_POLYGON, process))
}

export const setCategorizationMultiple = (request, params) => dispatch => {
  const process = async () => ({
    response: await ClientsService.setCategorizationMultiple(request, params),
  })
  dispatch(executeAction(actionTypes.ASSIGN_CATEGORIES_TO_MULTIPLE_CLIENTS, process))
}

export const deleteContact = id => dispatch => {
  const process = async () => await ClientsService.deleteContact(id)
  dispatch(executeAction(actionTypes.DELETE_CONTACT, process))
}

export const getClientContacts = id => dispatch => {
  const process = async () => ({ contacts: await ClientsService.getClientContacts(id) })
  dispatch(executeAction(actionTypes.GET_CONTACTS, process))
}

/**
 * Lock sales to a client
 * @param {number} id - Client id
 * @param {string} message - Lock reason
 */
export const lockClient = (id, message) => dispatch => {
  const process = async () => ({ payload: await ClientsService.lockClient(id, message) })
  dispatch(executeAction(actionTypes.LOCK_CLIENT, process))
}

/**
 * Unlock sales to a client
 * @param {number} id - Client id
 */
export const unlockClient = id => dispatch => {
  const process = async () => ({ payload: await ClientsService.unlockClient(id) })
  dispatch(executeAction(actionTypes.UNLOCK_CLIENT, process))
}

/**
 * Get the credit notes excel
 * @function
 * @param {Object} params Filters parameters
 * @param {number} params.startDate Initial date for taking results
 * @param {number} params.endDate Finish date for taking results
 * @param {number} params.clientId Filter to client
 * @param {boolean} params.isVouchers Filter to get credit notes or vouchers
 * @param {number} params.use Filter to status voucher
 */
export const getCreditNotesExcel = params => dispatch => {
  const process = async () => await ClientsService.creditNotesExcel(params)
  dispatch(executeAction(actionTypes.GET_CREDIT_NOTE_EXCEL, process))
}

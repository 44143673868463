import './ItemSelectionTable.scss'
import React, { useEffect, useState } from 'react'

import { Media } from 'react-breakpoints'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import {
  Button,
  Card,
  Checkbox,
  FormText,
  Icon,
  Money,
  Select,
  Switch,
  TableV2,
  Title,
} from '../index'

import {
  selectItemsToSelect,
  selectItemsToSelectDetail,
  selectLineItemsToSelect,
  selectOwnWarehouseLite,
  selectSellItemsToSelect,
} from 'src/selectors/warehouse.selector'
import { useDispatch, useSelector } from 'react-redux'
import {
  actionTypes,
  getItemsToSelect,
  getItemsToSelectDetail,
  getLineItemsToSelect,
  getWarehouseInventoryVariation,
  getWarehouseLite,
} from 'src/actions/warehouse.actions'
import { loadingSelector } from 'src/selectors/loading.selector'
import { TotalInfo } from './TotalInfo'
import { faPercent, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  getCompanyField,
  getCompanyFieldEditPricePurchase,
  getCompanyFieldProductRepeat,
} from 'src/actions/company.actions'
import { getAllCurrencies } from 'src/actions/currencies.actions'
import { getCategorizationByType } from 'src/actions/categorization.actions'
import { ItemRow } from 'src/components/Transaction/Row/ItemRow'
import { useLocation } from 'react-router-dom'
import { itemTypes } from 'src/utils/utilities'
import { selectDiscounts } from 'src/selectors/restaurant.selector'
import { getTipsAvailable } from 'src/actions/restaurant.actions'
import { ModalTaxFree } from '../Items/TaxFree/ModalTaxFree'
import {
  selectCompanyFieldById,
  selectCompanyFieldEditPricePurchase,
} from 'src/selectors/company.selector'
import { WarehouseVariatons } from 'src/content/Warehouse/WarehouseVariatons'
import { ItemTableMobile } from './MobileFlow/ItemTableMobile'
import { ModalItems } from './MobileFlow/ModalItems'
import { selectCurrentCurrency } from 'src/selectors/currencies.selector'
import { setGeneralConfig, setItemTable } from 'src/actions/transaction.action'
import {
  selectConfigTransactionTable,
  selectItemSelected,
} from 'src/selectors/transaction.selector'

import ItemCRUD from 'src/content/Products/ItemCRUD'

import { showAlert } from 'src/actions/alert.actions'
import { handlerInfo, hasErrorsSelector } from 'src/selectors/error.selector'
import { getUniversalItem } from 'src/utils/getUniversalItem'
import countriesEnum, { Country } from 'src/enums/countries'
import CSVLoaderV2, { CATEGORY_UPLOAD } from '../CSVLoader/CSVLoaderV2'
import { haveAnyValue } from 'src/utils/utilitiesV2'

interface Props {
  type: number
  balancePermission: boolean
  warehouseId?: number
  onWarehouse?: (warehouse: ISelect) => void
  onSelected?: (items: IItemSelectTable[], deleted: IItemSelectTable[]) => void
  details: IItemDetailToEdit[]
  selectedItems?: IItemSelectTable[]
  warehouseDisabled?: boolean
  hideItemImage?: boolean
  isEditParam?: boolean
  forDisplay?: boolean
  warehouseName?: string
  allInventory?: boolean
  seeInputTax?: boolean
}

/**
 * Tabla que permite la selección de items, en la creación y edición de transacciones
 * @component
 * @param {Props} props Props del componente
 * @param {number} props.type - Tipo de transacción
 * @param {boolean} props.balancePermission - Permisos de ver saldos monetarios
 * @param {number} props.warehouseId - Id de la bodega
 * @param {function} props.onWarehouse - Función que se ejecuta al cambiar la bodega
 * @param {function} props.onSelected - Función que se ejecuta al seleccionar un item, y devuelve el listado de items seleccionados
 * @param {IItemDetailToEdit[]} props.details - Detalles de la transacción a editar. Solo para edición de orden de venta.
 * @param {IItemSelectTable[]} props.selectedItems - Items a asignar for defecto.
 * @param {boolean} props.warehouseDisabled - Disabled selection warehouse
 * @param {boolean} props.hideItemImage - Hide item image column
 * @param {boolean} props.isEditParam - Indicates that it is an edition
 * @param {boolean} props.forDisplay - Indicates that it is for display and anything can be modified
 * @param {string} props.warehouseName - Name of the warehouse
 * @param {boolean} props.allInventory - Get all the inventory in the items to select
 * @param {boolean} props.seeInputTax - Show the input tax
 */
export const ItemSelectionTable = ({
  type,
  balancePermission,
  warehouseId,
  onWarehouse,
  onSelected,
  details = [],
  selectedItems = [],
  warehouseDisabled = false,
  hideItemImage = false,
  isEditParam = false,
  forDisplay = false,
  warehouseName,
  allInventory = false,
  seeInputTax = false,
}: Props) => {
  const dispatch = useDispatch()

  const lineItems: ILineItems = useSelector(selectLineItemsToSelect)
  const discountsOwn = useSelector(selectDiscounts)
  const disabledDiscounts = useSelector(state => selectCompanyFieldById(state, 84))
  const warehouseLocations = useSelector(state => selectCompanyFieldById(state, 123))
  const country = useSelector(state => selectCompanyFieldById(state, 134))
  const itemTable: IItemTable = useSelector(selectItemSelected)
  const generalConfigTable: IGeneralConfigTTable = useSelector(
    selectConfigTransactionTable,
  )
  const currentCurrency = useSelector(selectCurrentCurrency)

  const fieldEditPrice = useSelector(selectCompanyFieldEditPricePurchase)

  const inventory = useSelector(
    type === itemTypes.SELL ? selectSellItemsToSelect : selectItemsToSelect,
  )
  const warehouses = useSelector(selectOwnWarehouseLite)
  const inventoryDetails: IItemToSelectResponse[] = useSelector(selectItemsToSelectDetail)
  const loadingWarehouses = useSelector(state =>
    loadingSelector([actionTypes.GET_WAREHOUSE_LITE])(state),
  )
  const loadingItems = useSelector(state =>
    loadingSelector([actionTypes.GET_ITEMS_TO_SELECT])(state),
  )
  const loadingLineItems = useSelector(state =>
    loadingSelector([actionTypes.GET_LINE_ITEMS_TO_SELECT])(state),
  )
  const loadingItemsDetails = useSelector(state =>
    loadingSelector([actionTypes.GET_ITEMS_TO_SELECT_DETAIL])(state),
  )
  const loadingItemBarcode = useSelector(state =>
    loadingSelector([actionTypes.GET_ITEMS_TO_SELECT_BARCODE])(state),
  )
  const hasErrorBarcode = useSelector(state =>
    hasErrorsSelector([actionTypes.GET_ITEMS_TO_SELECT_BARCODE])(state),
  )

  const isEdit: boolean = useLocation().pathname.includes('/editar') || isEditParam
  const [discounts, setDiscounts] = useState([])
  const importation = useLocation().pathname.includes('/importaciones')
  const [selected, setSelected] = useState<IItemSelectTable[]>([]) // Lista de items seleccionados

  const [warehouse, setWarehouse] = useState({
    value: null,
    label: 'Sin seleccionar',
  })
  const [flagsItems, setFlagsItems] = useState({
    get: false,
    getLine: false,
    getItemVariation: false,
    getItemBarcode: false,
  })
  const [itemsToSelect, setItemsToSelect] = useState<IItemToSelectResponse[]>([])
  const [utils, setUtils] = useState({
    showCreateProduct: false,
    conversionCurrency: 7.6, // Conversion de moneda
    // Exentos
    showIva: false,
    // Descuentos generales
    isPercentDiscountAll: false,
    haveDiscountGeneral: false,
    // Importacion
    activateLocalCurrency: false,
    exchangeRate: { symbol: 'Q' },
    individualTotals: false,
  })
  const [listDeleted, setListDeleted] = useState([])
  const [listItemsToFill, setListItemsToFill] = useState<IItemDetailToEdit[]>([])
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    dispatch(getWarehouseLite())
    if (type !== itemTypes.RECIPE_ITEMS) {
      dispatch(getCompanyFieldProductRepeat())
      dispatch(getCategorizationByType(21))
      dispatch(getCompanyField(84))
      dispatch(getCompanyField(123))
      dispatch(getCompanyField(134))
      dispatch(getCompanyField(89))
      dispatch(getCompanyFieldEditPricePurchase())
      dispatch(getAllCurrencies())
      if (discountsOwn.length === 0) dispatch(getTipsAvailable(null))
    }
  }, [])

  useEffect(() => {
    if (selectedItems?.length > 0) {
      setSelected(selectedItems)
    }
  }, [selectedItems])

  useEffect(() => {
    if (details.length > 0 && (selected.length === 0 || forDisplay)) {
      if (forDisplay) setSelected([])
      dispatch(setItemTable({ index: 0 }))
      let listItemId = ''
      if (isEdit) {
        details.forEach(x => {
          if (x.product) listItemId += x.product.id + ','
          else if (x.productId) listItemId += x.productId + ','
        })
      }
      listItemId = listItemId.slice(0, listItemId.length - 1)
      const newDetails: IItemDetailToEdit[] = details.map((detail, i) => {
        return {
          baseProductQuantity: null,
          commentary:
            detail.product?.code === 'UNIX' ? detail.product.name : detail.commentary,
          discount: detail.discount,
          price: detail.price !== null ? detail.price : detail.subtotal / detail.quantity,
          subtotal: detail.total,
          quantity: detail.quantity,
          id:
            detail.product?.code === 'UNIX' ? 1 : detail.product?.id || detail.productId,
          listCategorization: detail.categories,
          listLocations: detail.listLocations,
          detailId: detail.id,
          edited: false,
          key: new Date().valueOf() + i,
          expirationDate: detail.expirationDate,
          series: detail.series,
          tax: detail.tax,
        }
      })

      setListItemsToFill(newDetails)
      const params = {
        warehouseId: warehouseId || warehouse.value,
        withZero: true,
        withCategories: true,
        type,
        list: listItemId,
        size: details.length,
      }
      dispatch(getItemsToSelectDetail(params))
    }
  }, [details])

  useEffect(() => {
    if (onSelected) {
      onSelected(
        selected.filter(s => s && s.value !== undefined),
        listDeleted,
      )
    }
  }, [JSON.stringify(selected)]) //, localCurrency])

  useEffect(() => {
    if (itemTable.saveItemModal) {
      if (itemTable.index !== null) {
        selected[itemTable.index] = itemTable.item
        setSelected([...selected])
      } else selected.push(itemTable.item)
      dispatch(
        setItemTable({
          item: {},
          index: null,
          saveItemModal: false,
        }),
      )
      dispatch(setGeneralConfig({ showList: false, showDetails: false }))
    }
  }, [itemTable.saveItemModal])

  useEffect(() => {
    // Se setean los descuentos propios de la empresa
    if (discountsOwn.length > 0) {
      if (discounts.length === 0) {
        setDiscounts(
          discountsOwn.map(x => {
            return { label: x.percentage + '%  ' + x.alias, value: x.percentage }
          }),
        )
      }
    }
  }, [discountsOwn])

  useEffect(() => {
    // Se setea el árbol de ítems del item que fue seleccionado

    if (loadingLineItems) setFlagsItems({ ...flagsItems, getLine: true })
    else if (flagsItems.getLine) {
      setFlagsItems({ ...flagsItems, getLine: false })
      if (lineItems) {
        const { index } = itemTable

        if (lineItems.line?.length === 0) return
        if (
          type === itemTypes.SELL ||
          type === itemTypes.EXPENSE ||
          type === itemTypes.RECIPE_ITEMS
        )
          lineItems.line[0].quantity = 1
        else if (
          (type === itemTypes.PURCHASE && fieldEditPrice) ||
          type === itemTypes.PRODUCTION
        )
          lineItems.line[0].ownPrice = selected[index]?.productionPrice
        if (isMobile)
          dispatch(
            setItemTable({
              item: {
                ...itemTable.item,
                lineItems: lineItems,
                line: lineItems.line,
                baseProductQuantity:
                  itemTable.item?.baseProductQuantity !== null
                    ? itemTable.item.baseProductQuantity
                    : lineItems.baseExistence,
                subProductValue: lineItems.subProductValue,
                list: lineItems.list,
                render: true,
              },
            }),
          )
        else if (selected.length > 0 && selected[index]) {
          selected[index].lineItems = lineItems
          selected[index].line = lineItems.line
          selected[index].baseProductQuantity = lineItems.baseExistence
          selected[index].subProductValue = lineItems.subProductValue
          selected[index].list = lineItems.list
          setSelected([...selected])
        }
      }
    }
  }, [loadingLineItems])

  // Seleccionando bodega por defecto
  useEffect(() => {
    if (!warehouses) return
    if (warehouses.length > 0 && warehouse.value === null) {
      if (warehouseId)
        setWarehouse(
          warehouses.find(warehouse => warehouse.value === warehouseId) || {
            value: warehouseId,
            label: warehouseName,
          },
        )
      else setWarehouse(warehouses[0])
    }
  }, [warehouses])

  useEffect(() => {
    if (!warehouse.value) return
    if (onWarehouse && type === itemTypes.SELL) onWarehouse(warehouse)
    setUp()
  }, [warehouse])

  useEffect(() => {
    if (generalConfigTable.getItemValue) {
      const req = {
        warehouseId: warehouseId || warehouse.value,
        productId: generalConfigTable.value,
        type: type,
      }
      dispatch(
        getLineItemsToSelect(
          generalConfigTable.params !== undefined ? generalConfigTable.params : {},
          req,
        ),
      )
      dispatch(
        setGeneralConfig({
          getItemValue: false,
          params: undefined,
        }),
      )
    }
  }, [generalConfigTable.getItemValue])

  useEffect(() => {
    if (generalConfigTable.getVariationsValue) {
      dispatch(
        getWarehouseInventoryVariation(
          warehouseId || warehouse.value,
          generalConfigTable.value,
          generalConfigTable.params !== undefined
            ? generalConfigTable.params
            : {
                limit: true,
                type: type,
              },
        ),
      )
      dispatch(
        setGeneralConfig({
          getVariationsValue: false,
          params: undefined,
          showList: true,
        }),
      )
    }
  }, [generalConfigTable.getVariationsValue])

  useEffect(() => {
    if (loadingItems) setFlagsItems({ ...flagsItems, get: true })
    else if (flagsItems.get) {
      setFlagsItems({ ...flagsItems, get: false })
      setItemsToSelect(inventory)
      if (selected.length === 0 && !isEdit && !isMobile) {
        setSelected([
          ...selected,
          {
            value: null,
            label: 'Seleccionar',
            key: new Date().valueOf(),
          },
        ])
      }
    }
  }, [loadingItems])

  useEffect(() => {
    if (loadingItemsDetails) setFlagsItems({ ...flagsItems, getItemVariation: true })
    else if (flagsItems.getItemVariation) {
      setFlagsItems({ ...flagsItems, getItemVariation: false })
      if (itemTable.index === null) {
        // Barcode
        const itemResponse = inventoryDetails[0]
        selected.push(
          fillDetail(itemResponse, {
            categories: [],
            discount: 0,
            quantity: 1,
            listLocations: [],
            key: new Date().valueOf(),
          }),
        )
      } else {
        const listDetails = fillSelectDetails(inventoryDetails)
        selected.splice(itemTable.index, 1)
        listDetails.forEach((item, index) => {
          selected.splice(itemTable.index + index, 0, item)
        })
        setSelected(selected)
        setListItemsToFill([])
      }
    }
  }, [loadingItemsDetails])

  useEffect(() => {
    if (loadingItemBarcode) setFlagsItems({ ...flagsItems, getItemBarcode: true })
    else if (flagsItems.getItemBarcode) {
      setFlagsItems({ ...flagsItems, getItemBarcode: false })
      if (hasErrorBarcode) return
      const item = inventoryDetails[0]
      if (item) {
        if (!selected.some(s => s.value === item.id)) {
          selected.push(
            fillDetail(item, {
              categories: [],
              discount: 0,
              quantity: 1,
              listLocations: [],
              baseProductQuantity: item?.lineItems?.baseExistence || 0,
              key: new Date().valueOf(),
            }),
          )
        } else {
          const key = selected.findIndex(s => s.value === item.id)
          selected[key].line[0].quantity = (selected[key].line[0].quantity || 0) + 1
          selected[key].subTotal =
            (selected[key].line[0].quantity || 1) *
              (selected[key].line[0].productData.price || 0) -
            parseFloat((selected[key].discount || 0).toString())
          selected[key].render = !selected[key].render
        }
        setSelected(selected)
      }
    }
  }, [loadingItemBarcode])

  /**
   * Agrega los items provenientes de seleccion de variaciones/ubicaciones o de edicion de items. (listItemsToFill)
   * @param data Arreglo de items a agregar con la informacion general del item (precio, linea, etc).
   * @returns None
   */
  const fillSelectDetails = (data: IItemToSelectResponse[]): IItemSelectTable[] => {
    if (!data) return []
    const filledDetails: IItemSelectTable[] = []
    listItemsToFill.forEach((aux: IItemDetailToEdit) => {
      const detail = data.find(x => x.id === aux.id)
      if (detail !== null && detail !== undefined) {
        filledDetails.push(fillDetail(detail, aux))
      }
    })
    const extraDetails: IItemSelectTable[] = listItemsToFill
      .filter(detail => detail.id === 1)
      .map(detail =>
        getUniversalItem({
          ...detail,
        }),
      )
    return filledDetails.concat(extraDetails)
  }

  /**
   * Crea el objeto usable en la tabla de seleccion, pasando la informacion necesaria a un nuevo objeto, cuando se agregan
   * items desde el modal de variacioens o ubicaciones.
   * @param itemTemplate Informacion general del item, con la line de items.
   * @param item Item a agregar con los valores especificos de variacion, ubicacion, cantidad, cantidad base, etc.
   * @returns
   */
  const fillDetail = (
    itemTemplate: IItemToSelectResponse,
    item: IItemDetailToEdit,
  ): IItemSelectTable => {
    const template: IItemSelectTable = JSON.parse(JSON.stringify(itemTemplate))

    if (template.lineItems.line.length === 0) return
    template.lineItems.line[0].quantity = item.quantity
    const price = haveAnyValue(item.price) ? item.price : itemTemplate.price

    if (itemTypes.PURCHASE === type || itemTypes.EXPENSE === type) {
      const cost = item.price || itemTemplate.productionPrice || price
      template.lineItems.line[0].ownPrice = cost
      item.subtotal = cost * item.quantity
    }

    if (item.series?.length > 0) {
      template.lineItems.line[0].series = item.series.map(s => s.serie)
      template.activeSeries = true
    }

    if (template.inBatches) {
      template.originDocumentId = item.originDocumentId
      template.originDocumentTypeId = item.originDocumentTypeId
    }

    return {
      ...template,
      label: template.code + '-' + (template.label || template.name),
      includeIva: true,
      line: template.lineItems.line,
      subProductValue: template.lineItems.subProductValue,
      list: template.lineItems.list,
      baseProductQuantity:
        item.baseProductQuantity !== null
          ? item.baseProductQuantity
          : template.lineItems.baseExistence,
      listVariations: item.listCategorization,
      listLocations: item.listLocations,
      price: price,
      haveQuantities: true,
      discount: item.discount || 0,
      value: template.id,
      key: item.key,
      edited: item.edited,
      detailId: item.detailId,
      commentary: item.commentary,
      selectedPrice: {
        label: (price || 0).toString(),
        value: price,
        alias: (price || 0).toString(),
      },
      subTotal: item.subtotal,
      expirationDate: item.expirationDate,
      tax: item.tax,
    }
  }

  /**
   *  Descuento general, cambiar el valor del descuento de todos los items.
   * @param {ISelect} discount Valor del descuento
   * @param {boolean} isPercent Indica si el descuento es por porcentaje
   */
  const changeAllDiscount = (discount: ISelect, isPercent: boolean) => {
    if (discount) {
      let totalDiscount = 0
      selected.forEach((item, index) => {
        item.error = null
        if (!item.value) {
          return
        }
        item.percentDiscount = isPercent
        if (isPercent) {
          changePercentDiscount(item, discount)
        } else {
          let descuento = getNewDiscount(item, discount.value)
          totalDiscount += Number(descuento.toFixed(2))
          if (index === selected.length - 1) {
            // Cuadrando los descuentos.
            const dif = Number(discount.value) - totalDiscount
            if (dif > 0) descuento = descuento - dif
            else descuento = descuento + dif
          }
          changeDiscount(item, Number(descuento.toFixed(2)))
        }
      })
      setSelected([...selected])
    }
  }
  const getTotalWithOutDiscount = () => {
    let total = 0
    selected.map(item => (total += item.subTotalWithoutDiscount))
    return total
  }
  const getNewDiscount = (item, discount) => {
    const total = getTotalWithOutDiscount()
    return (item.subTotalWithoutDiscount * discount) / total
  }

  /**
   * Metodo que calcula el descuento simple de un item
   * @param {*} item IItemSelectTable
   * @param {*} value
   */
  const changeDiscount = (item: IItemSelectTable, value: number) => {
    if (isEdit && item.detailId) {
      item.edited = true
    }
    item.error = null
    if (!isNaN(value)) {
      let discount = value
      if (!discount || discount < 0) discount = 0
      else if (
        item.maxDiscount &&
        discount > Number.parseFloat(item.maxDiscount.toString()) &&
        item.id !== 1
      ) {
        item.error = 'El valor ingresado supera el descuento maximo'
        discount = item.maxDiscount

        if (item.percentDiscount) {
          item.discountPercentSelected = discounts.find(
            x => x.value === (item.maxDiscount / item.subTotalWithoutDiscount) * 100,
          )
          item.error = 'El porcentaje excede el descuento maximo'
        }
      } else if (discount > item.subTotalWithoutDiscount) {
        item.error = 'El valor ingresado supera el subtotal'
        discount = item.subTotalWithoutDiscount
      }
      item.discount = discount
      if (item.haveQuantities) item.subTotal = getSubTotalV2(item)

      setSelected([...selected])
    }
  }

  /**
   * Metodo que calcula el porcentaje descuento y lo setea
   * @param {IItemSelectTable} item Item de la tabla
   * @param {ISelect} discount Descuento seleccionado
   */
  const changePercentDiscount = (item: IItemSelectTable, discount: ISelect) => {
    if (!isNaN(Number(discount.value))) {
      item.discountPercentSelected = discount
      changeDiscount(
        item,
        (item.subTotalWithoutDiscount / 100) * Number(item.discountPercentSelected.value),
      )
    }
  }

  /**
   * Obtiene el subtotal de un item
   * @param {IItemSelectTable} item Item de la tabla
   * @returns subtotal con descuento
   */
  const getSubTotalV2 = (item: IItemSelectTable) => {
    return item.subTotalWithoutDiscount - (item.discount || 0)
  }

  /**
   * Obtiene el total de la transaccion..
   * @param {string} param Parametro a obtener
   * @returns total de la transaccion
   */
  const getTotalValue = (param = 'subTotal'): number => {
    let response = 0
    selected &&
      selected.forEach(s => {
        if (s && s[param]) {
          let value = Number(s[param]) || 0
          if (seeInputTax) value += Number(s.tax) || 0

          response += value
        }
      })
    return response
  }

  /**
   * Metodo para obtener los items a seleccionar
   * @param {string} search Buscador
   * @returns
   */
  const setUp = async (search?: string) => {
    const params = {
      warehouseId: warehouseId || warehouse.value,
      withZero: true,
      withCategories: true,
      type,
      allInventory: allInventory || false,
      search,
    }

    dispatch(getItemsToSelect(params))
  }

  /**
   * Actualiza todos los items con exento de IVA.
   * @param item Item de la tabla
   */
  const updateTaxFree = item => {
    selected.forEach(it => {
      updateTaxItem(it, item)
    })
    setUtils({ ...utils, showIva: false })
    setSelected([...selected])
  }

  /**
   * Setea los nuevos valores del IVA al item de la tabla
   * @param item Item de la tabla
   * @param value Item con los valores del IVA
   */
  const updateTaxItem = (item: IItemSelectTable, value: IItemSelectTable) => {
    if (isEdit && item.detailId) item.edited = true
    item.includeIva = value.includeIva
    item.phraseReference = value.phraseReference
  }

  const headers = [
    { show: true, index: 0 },

    {
      label: 'Item',
      show: true,
      className:
        type === itemTypes.RECIPE_ITEMS
          ? 'medium-t-transaction '
          : 'large-t-transaction ',
    },
    {
      label: 'Ubicación',
      show:
        warehouseLocations.value !== '0' &&
        (type === itemTypes.SELL ||
          type === itemTypes.WASTE ||
          type === itemTypes.TRANSFER),
    },
    {
      label: 'Cantidad',
      show: true,
      className: [itemTypes.PURCHASE, itemTypes.EXPENSE, itemTypes.WASTE].some(
        x => x === type,
      )
        ? 'small-t-transaction'
        : 'style-quantity',
    },
    {
      label: 'Costo Unitario',
      show:
        type === itemTypes.PURCHASE ||
        type === itemTypes.EXPENSE ||
        type === itemTypes.PRODUCTION,
      style: { width: '150' },
    },
    {
      label: 'Precio',
      show: [itemTypes.SELL, itemTypes.ADDONS].some(x => x === type),
      className: 'small-t-transaction',
    },
    {
      label: 'Descuento',
      show: type === itemTypes.SELL && disabledDiscounts.value === 'false',
      style: { width: '150' },
    },
    {
      label: 'SubTotal',
      show: [
        itemTypes.SELL,
        itemTypes.PURCHASE,
        itemTypes.EXPENSE,
        itemTypes.ADDONS,
        itemTypes.RECIPE_ITEMS,
      ].some(x => x === type),
      className:
        !fieldEditPrice &&
        (type === itemTypes.PURCHASE ||
          type === itemTypes.EXPENSE ||
          type === itemTypes.RECIPE_ITEMS)
          ? 'small-t-transaction'
          : 'mini-t-transaction',
    },
    {
      label: 'Impuesto',
      show: seeInputTax,
      className: 'small-t-transaction',
    },
    {
      label: 'Total',
      show: seeInputTax,
      className: 'small-t-transaction',
    },
    {
      label: 'Fecha de vencimiento',
      show: type === itemTypes.PURCHASE,
    },
    { show: !forDisplay, label: 'Acciones' },
  ]

  /**
   * Metodo para identificar el item que se desea eliminar y guardarlo en una lista.
   * @param {IItemSelectTable} item Index del item a eliminar
   */
  const onDiscardItem = (item: IItemSelectTable) => {
    const index = selected.findIndex(x => x.key === item.key)

    if (isEdit && selected[index].detailId) {
      const item = selected[index]
      item.deleted = true
      setListDeleted(listDeleted.concat(item))
    }
    selected.splice(index, 1)
    setSelected([...selected])
  }

  const fillProductByCSV = data => {
    if (!data) return []
    const index = selected.length + 1
    return data.map((item, key) =>
      fillDetail(
        { ...item, id: item.productId, name: item.productName },
        {
          listCategorization: item.listCategories,
          discount: 0,
          quantity: item.quantity,
          price: item.subtotal / item.quantity,
          subtotal: item.subtotal,
          key: index + key,
          expirationDate: item.expirationDate,
        },
      ),
    )
  }

  /**
   * Filtros de la tabla.
   * @returns Filters
   */
  const customFilter = () => {
    const { withOutIVA } = generalConfigTable
    return (
      <Row className={'container-buttons'}>
        {itemTypes.SELL === type && (
          <Col xl={12}>
            <Row>
              {selected && selected.length > 0 && (
                <Col xl={12}>
                  <Row className={'container-buttons'}>
                    <div className={'column ml-3'}>
                      <span>Descuentos</span>

                      <div className={'d-flex'}>
                        {utils.isPercentDiscountAll ? (
                          <Select
                            mt={0}
                            style={{ width: 120, flex: 1 }}
                            withoutLabel
                            onChange={value => {
                              changeAllDiscount(value, true)
                            }}
                            options={discounts}
                          />
                        ) : (
                          <FormText
                            style={{ width: 100 }}
                            mt={1}
                            type={'number'}
                            onChange={({ target }) => {
                              if (target.value === '') {
                                setUtils({ ...utils, haveDiscountGeneral: false })
                                dispatch(setGeneralConfig({ haveDiscountGeneral: false }))
                              } else if (!utils.haveDiscountGeneral) {
                                setUtils({ ...utils, haveDiscountGeneral: true })
                                dispatch(setGeneralConfig({ haveDiscountGeneral: true }))
                              }
                              changeAllDiscount(
                                {
                                  value: target.value === '' ? 0 : target.value,
                                  label: '',
                                },
                                false,
                              )
                            }}
                          />
                        )}

                        <div className={'column'}>
                          <Checkbox
                            /* @ts-expect-error Error por tipado JS*/
                            type="checkbox"
                            id="idchk"
                            name="chk"
                            label={'%'}
                            topLabel
                            checked={utils.isPercentDiscountAll}
                            onChange={({ target }) => {
                              setUtils({
                                ...utils,
                                isPercentDiscountAll: target.checked,
                                haveDiscountGeneral: target.checked,
                              })
                              dispatch(
                                setGeneralConfig({ haveDiscountGeneral: target.checked }),
                              )
                              changeAllDiscount({ label: '0%', value: 0 }, target.checked)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        )}

        {(itemTypes.PURCHASE === type || itemTypes.EXPENSE === type) && (
          <Col xl={12} md={12} xs={12}>
            <Row className={'container-buttons'}>
              <Col xl={6} md={12} xs={12}>
                {/* @ts-expect-error JS */}
                <CSVLoaderV2
                  category={CATEGORY_UPLOAD.LOAD_PRODUCT_PURCHASE}
                  title={'Cargar Productos'}
                  onFinishLoad={data => {
                    const sel = fillProductByCSV(data.list)
                    setSelected(selected.concat(sel))
                  }}
                  warehouseId={warehouseId}
                  type={0}
                />
              </Col>

              <div className={'column'}>
                <span>Artículos sin IVA</span>

                <div className={'d-flex'}>
                  <Switch
                    checked={withOutIVA.active}
                    style={{ marginTop: 5 }}
                    onChange={({ target }) => {
                      const { checked } = target
                      dispatch(
                        setGeneralConfig({ withOutIVA: { active: checked, value: 12 } }),
                      )
                    }}
                  />
                  {withOutIVA.active && !importation && (
                    <FormText
                      mt={0}
                      name={'sin_iva'}
                      value={withOutIVA.value}
                      placeholder={'12'}
                      append={<Icon icon={faPercent} />}
                      onChange={({ target }) => {
                        let { value } = target
                        value = isNaN(value) ? 0 : Number(value)
                        if (!value || value < 0) value = 0
                        else if (value > 100) value = 100
                        dispatch(
                          setGeneralConfig({
                            withOutIVA: { active: withOutIVA.active, value },
                          }),
                        )
                      }}
                    />
                  )}
                </div>
              </div>
              <Col>
                <Switch
                  checked={utils.individualTotals}
                  label={'Valores monetarios individuales'}
                  topLabel
                  onChange={({ target }) => {
                    const { checked } = target
                    setUtils({ ...utils, individualTotals: checked })
                    selected.forEach(item => {
                      if (item.id !== null && item.individualTotal !== checked) {
                        item.individualTotal = checked
                        item.subTotal = 0
                        item.line.map(x => (x.subTotal = 0))
                      }
                    })
                  }}
                  info={
                    'Aplicar valores monetarios de manera individual a cada item de un árbol de ítems'
                  }
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    )
  }
  const renderDesktop = (item: IItemSelectTable, index: number) => {
    return (
      <>
        <ItemRow
          hideItemImage={hideItemImage}
          item={item}
          itemsToSelect={itemsToSelect}
          index={index}
          warehouseId={warehouseId || warehouse.value}
          selected={selected}
          type={type}
          isEdit={isEdit}
          updateValues={() => {
            setSelected([...selected])
          }}
          // Descuentos
          discounts={discounts}
          onChangeDiscount={changeDiscount}
          onChangePercentDiscount={changePercentDiscount}
          disableDiscount={utils.haveDiscountGeneral}
          //Exento
          updateTax={(itemEdit: IItemSelectTable) => {
            updateTaxItem(item, itemEdit)
            setSelected([...selected])
          }}
          // Accion eliminar
          onDiscardItem={() => onDiscardItem(item)}
          // Ubicaciones
          locations={warehouseLocations && warehouseLocations.value !== '0'}
          // Importacion y compras/gastos
          currency={
            importation
              ? utils.activateLocalCurrency
                ? utils.exchangeRate.symbol
                : '$'
              : currentCurrency.symbol
          }
          style={forDisplay ? { pointerEvents: 'none' } : {}}
          forDisplay={forDisplay}
          seeInputTax={seeInputTax}
        />
      </>
    )
  }
  const renderMobile = (item: IItemSelectTable, index: number) => {
    return (
      <div style={forDisplay ? { pointerEvents: 'none' } : {}}>
        <ItemTableMobile
          index={index}
          item={item}
          onDiscardItem={() => onDiscardItem(item)}
        />
      </div>
    )
  }

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        const mobile = breakpoints[currentBreakpoint] <= breakpoints.mobileLandscape
        setIsMobile(mobile)
        return (
          <div>
            {type === itemTypes.SELL && mobile && (
              <>
                <Col md={6} xs={6} xss={12}>
                  <Card>
                    <TotalInfo label="Total" value={getTotalValue('subTotal')} />
                  </Card>
                </Col>
                <Col md={6} xs={6}>
                  <Card>
                    <TotalInfo label="Descuento" value={getTotalValue('discount')} />
                  </Card>
                </Col>
              </>
            )}
            <Col md={12} xs={12} xl={12}>
              {!forDisplay && (
                <Title
                  action={
                    type === itemTypes.EXPENSE ||
                    type === itemTypes.PURCHASE ||
                    type === itemTypes.SELL
                  }
                  actionTitle={'Crear producto'}
                  dontHide
                  onClick={() => setUtils({ ...utils, showCreateProduct: true })}
                />
              )}
              <Card>
                <Row>
                  {type === itemTypes.SELL && !forDisplay && (
                    <Col>
                      <Select
                        label={'Bodegas'}
                        value={warehouse}
                        disabled={loadingWarehouses || warehouseDisabled}
                        onChange={value => {
                          if (onWarehouse) onWarehouse(value)
                          setWarehouse(value)
                        }}
                        options={warehouses}
                        loading={loadingWarehouses}
                      />
                    </Col>
                  )}
                  {balancePermission && !mobile && (
                    <>
                      <TotalInfo label="Total" value={getTotalValue('subTotal')} />
                      {type === itemTypes.SELL && (
                        <TotalInfo label="Descuento" value={getTotalValue('discount')} />
                      )}
                    </>
                  )}
                </Row>
                <Row>
                  <Col>
                    <TableV2
                      /* @ts-expect-error Error por  JS*/
                      loading={loadingItemsDetails || loadingItems}
                      pSize={4}
                      customClass={'scroll-x-without-height'}
                      hideEmpty
                      customFilter={forDisplay ? undefined : customFilter()}
                      renderRow={mobile ? renderMobile : renderDesktop}
                      items={selected}
                      headers={mobile ? undefined : headers}
                      buttonTitle={'Agregar ítem'}
                      onScan={(barcode: string) => {
                        if (barcode !== '') {
                          const params = {
                            warehouseId: warehouseId || warehouse.value,
                            withZero: true,
                            withCategories: true,
                            type,
                            barcode: barcode,
                          }
                          dispatch(getItemsToSelectDetail(params))
                        }
                      }}
                    />
                  </Col>
                  <Col xl={12}>
                    <div className={'f-actions'}>
                      <div className={'f-actions-body'}>
                        {!forDisplay && (
                          <div className={`f-actions-button pl-2 pr-2`}>
                            <Button
                              disabled={loadingItems || loadingItemsDetails}
                              loading={loadingItems || loadingItemsDetails}
                              className={'f-button-width'}
                              icon={faPlus}
                              onClick={() => {
                                if (warehouse.value === null)
                                  return dispatch(
                                    showAlert(
                                      handlerInfo(
                                        'No se ha seleccionado bodega',
                                        'Para realizar ventas debe ser encargado de al menos una bodega',
                                      ),
                                    ),
                                  )

                                if (mobile) {
                                  dispatch(
                                    setGeneralConfig({
                                      showModalItems: true,
                                    }),
                                  )
                                  dispatch(setItemTable({ index: null, item: {} }))
                                } else
                                  setSelected([
                                    ...selected,
                                    {
                                      value: null,
                                      label: 'Seleccionar',
                                      key: new Date().valueOf(),
                                    },
                                  ])
                              }}>
                              Agregar Ítem
                            </Button>
                          </div>
                        )}
                        {type === itemTypes.SELL && (
                          <div
                            className={`f-actions-total pl-2 pr-2 ${
                              isMobile ? ' mb-1 ' : ''
                            }`}
                            style={forDisplay ? { width: '100%' } : {}}>
                            <div className={'column'}>
                              <div className={'space-between'}>
                                <div className={'b-user-name'}>Descuento</div>
                                <div className={'b-user-email'}>
                                  <Money>{getTotalValue('discount')}</Money>
                                </div>
                              </div>
                              <div className={'space-between'}>
                                <div className={'b-user-name'}>Total</div>
                                <div className={'b-user-email'}>
                                  <Money>{getTotalValue('subTotal')}</Money>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <ModalTaxFree
              show={utils.showIva}
              item={{}}
              onClose={() => setUtils({ ...utils, showIva: false })}
              updateTax={updateTaxFree}
            />
            {/* @ts-expect-error Error por tipado JS*/}
            <WarehouseVariatons
              id={warehouseId || warehouse.value}
              baseItemData={itemTable.item}
              productId={generalConfigTable.value}
              show={generalConfigTable.showList}
              inBatches={itemTable.inBatches}
              onHide={() => {
                dispatch(
                  setGeneralConfig({
                    showList: false,
                  }),
                )
              }}
              forOuts
              typeTransaction={type}
              onSave={(items: IItemVariationLite[], noInventory) => {
                dispatch(
                  setGeneralConfig({
                    showList: false,
                  }),
                )

                const currentItem = selected[itemTable.index]
                const listToFill: IItemDetailToEdit[] = noInventory
                  ? [
                      {
                        categories: [],
                        quantity: 1,
                        price: currentItem.price,
                        discount: currentItem.discount,
                        baseProductQuantity: 1,
                        listCategorization: currentItem.listVariations,
                        listLocations: currentItem.listLocations,
                        id: currentItem.value,
                        key: currentItem.key,
                        detailId: currentItem.detailId,
                        edited: currentItem.edited,
                        commentary: currentItem.commentary,
                      },
                    ]
                  : items.map((item: IItemVariationLite, i) => {
                      return {
                        quantity: item.quantity,
                        discount: 0,
                        baseProductQuantity: item.baseProductQuantity,
                        listCategorization: item.listCategorization,
                        categories: item.listCategorization,
                        listLocations: item.listLocations,
                        id: currentItem.value,
                        key: new Date().valueOf() + i,
                        detailId: i === 0 ? currentItem.detailId : undefined,
                        edited: i === 0 && isEdit ? true : undefined,
                        originDocumentId: currentItem.inBatches
                          ? item.originDocumentId
                          : null,
                        originDocumentTypeId: currentItem.inBatches
                          ? item.originDocumentTypeId
                          : null,
                        commentary: currentItem.inBatches
                          ? item.originDocumentType + ': ' + item.originDocumentNumber
                          : null,
                      }
                    })
                setListItemsToFill(listToFill)
                const params = {
                  warehouseId: warehouseId,
                  withZero: true,
                  withCategories: true,
                  withExistence: true,
                  type,
                  list: currentItem.value,
                }
                dispatch(getItemsToSelectDetail(params))
              }}
              mobileOuts={mobile}
              dontGetCategorization
            />
            <ModalItems
              setUp={search => setUp(search)}
              selected={selected}
              itemsToSelect={itemsToSelect}
              type={type}
              discounts={discounts}
              isEdit={isEdit}
              onChangeDiscount={changeDiscount}
              onChangePercentDiscount={changePercentDiscount}
              useLocations={warehouseLocations && warehouseLocations.value !== '0'}
              seeInputTax={seeInputTax}
            />

            <ItemCRUD
              show={utils.showCreateProduct}
              createdByTransaction={type}
              onHide={(created: boolean, itemCreated) => {
                setUtils({ ...utils, showCreateProduct: false })
                if (created && itemCreated) {
                  dispatch(
                    setItemTable({
                      index: null,
                    }),
                  )
                  setSelected([...selected])
                  const params = {
                    warehouseId: warehouseId || warehouse.value,
                    withZero: true,
                    withCategories: true,
                    type,
                    list: itemCreated?.id?.toString(),
                  }
                  dispatch(getItemsToSelectDetail(params))
                }
              }}
            />
          </div>
        )
      }}
    </Media>
  )
}
